<template>

  <Vue3Signature  ref="signature1" :sigOption="state.option" :w="'400px'" :h="'400px'"
                  :disabled="state.disabled" class="example"></Vue3Signature>
  <button @click.prevent="save('image/jpeg')">Save</button>
  <button @click.prevent="clear">Clear</button>

</template>

<script setup>
import {reactive, ref} from 'vue'
import {Notify} from "quasar";
import { defineProps } from 'vue';

const props = defineProps({
  item_id: String,
})

const state = reactive({
  count: 0,
  option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(255,255,255)"
  },
  disabled: false
})

const signature1 = ref(null)

const save = () => {
  let customerSignature = signature1.value.save('image/jpeg')
  localStorage.setItem(props.item_id+'_signature_base64', (customerSignature));
  Notify.create({
    color: 'green-5',
    textColor: 'white',
    icon: 'cloud_done',
    message: 'Signature Saved'
  })
  state.disabled = !state.disabled;
  fromDataURL(customerSignature);
}

const clear = () => {
  signature1.value.clear()
}

// eslint-disable-next-line no-unused-vars
const fromDataURL = (url) => {
  signature1.value.fromDataURL(url);
}


</script>

<style scoped>
.example{
  margin: 0 auto;
}
</style>