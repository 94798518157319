import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from './layouts/Default.vue'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Logout from './views/Logout.vue'
import OpenTasks from "@/views/OpenTasks";
import TasksExecute from "@/views/TasksExecute";
import AcceptedTasks from "@/views/AcceptedTasks";
import ClosedTasks from "@/views/ClosedTasks";
import UserFiles from "@/views/UserFiles";
import CaseFiles from "@/views/CaseFiles";
import TaskFiles from "@/views/TaskFiles";
import Message from "@/views/Message";
import Inbox from "@/views/Inbox";
import ComposeMessage from "@/views/ComposeMessage";
import OpenCheckLists from "@/views/OpenCheckLists.vue";
import ExecuteChecklist from "@/views/ExecuteChecklist.vue";

const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home
      },
      {
        path: '/login',
        name: 'login',
        component: Login
      },
      {
        path: '/open-tasks',
        name: 'open-tasks',
        component: OpenTasks
      },
      {
        path: '/open-checklists',
        name: 'open-checklists',
        component: OpenCheckLists
      },
      {
        path: '/execute-checklists/:id',
        name: 'execute-checklists',
        component: ExecuteChecklist
      },
      {
        path: '/accepted-tasks',
        name: 'accepted-tasks',
        component: AcceptedTasks
      },
      {
        path: '/closed-tasks',
        name: 'closed-tasks',
        component: ClosedTasks
      },
      {
        path: '/inbox',
        name: 'inbox',
        component: Inbox
      },
      {
        path: '/message/:id/:case_id',
        name: 'message',
        component: Message
      },
      {
        path: '/compose-message',
        name: 'compose-message',
        component: ComposeMessage
      },
      {
        path: '/execute-task/:id',
        name: 'execute-task',
        component: TasksExecute
      },
      {
        path: '/user-files',
        name: 'user-files',
        component: UserFiles
      },
      {
        path: '/case-files',
        name: 'case-files',
        component: CaseFiles
      },
      {
        path: '/task-files',
        name: 'task-files',
        component: TaskFiles
      },
      {
        path: '/logout',
        name: 'logout',
        component: Logout
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
