<template>
  <div class="hello">
    <q-card class="my-card">
      <q-card-section>
        <q-item-label class="text-h3">
          Welcome to the Bakers Driver APP
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <a href="https://www.bakerssalimited.com/" target="_blank" rel="noopener">
          <q-img width="80%" src='https://pbs.twimg.com/profile_banners/738371863248789504/1465813333/1500x500'/>
        </a>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-list bordered>
          <q-item>
            <label>Choose Branch:</label>
          </q-item>
          <q-item>
            <q-select
                filled
                v-model="this.branch"
                emit-value
                :options="this.branches"
                label="Branch"
                option-label="client"
                class="full-width"
            ></q-select>
          </q-item>
          <q-item>
            <q-btn @click="setBranch">
              Update
            </q-btn>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
    <q-dialog persistent v-model="this.show_survey" transition-show="rotate" transition-hide="rotate">
      <q-card class="bg-teal text-white" style="width: 100%">
        <q-card-section class="q-pt-none">
          <q-form
              method="post" @submit="this.submitSurvey()"
              class="q-gutter-md">
            <div>
              <q-card-section>
                <div class="text-h6">Survey</div>
              </q-card-section>
              <q-card-section>
                <q-select
                    filled
                    v-model="this.are_you_using_app"
                    emit-value
                    :options="['Yes','No']"
                    label="Are you using the app?"
                ></q-select>
                <q-select
                    v-if="this.are_you_using_app==='No'"
                    filled
                    v-model="this.why_not"
                    emit-value
                    :options="[
                        'I require more training',
                        'I attempted to use, but my tasks are not available',
                        'I do not want to use the App'
                    ]"
                    label="Why are you not using it?"
                ></q-select>
              </q-card-section>

            </div>
          </q-form>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn label="Done" @click="submitSurvey()" type="submit" color="primary"></q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>

    <q-card class="my-card">
      <q-card-section>
        <q-list bordered>
          <div  v-for="(conversation,index) in this.conversations" :key=index :index="this.draw_list" >
            <q-item >
              <q-item-section class="text-h5" :id="index">
                {{conversation.name}}
              </q-item-section>
              <q-form method="delete"  @submit="this.deleteConversation(conversation.id)"
                      class="q-gutter-md">
                <q-item-section avatar>
                  <q-btn clickable v-ripple type="submit" color="red" icon="delete" label="Delete"></q-btn>

                </q-item-section>
              </q-form>
            </q-item>
            <q-item>
              <q-item-section>
                {{conversation.message}}
              </q-item-section>
            </q-item>
          </div>
        </q-list>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import axios from "axios";
import { Notify } from 'quasar'
import NoSleep from 'nosleep.js';

export default {
  name: 'PageHome',
  watch: {
    survey_done: function (val) {
      console.log(val);
       if(val){
          Notify.create({
            color: 'green-5',
            textColor: 'white',
            icon: 'check',
            message: 'Please complete the driver survey',
            timeout: 10000,
          })
         this.show_survey = true;
       }
    }
  },
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
    const router = useRouter();
    let token = JSON.parse( localStorage.getItem('token') );
    let user_id = JSON.parse( localStorage.getItem('user_id') );
    if( !token ){
      router.push({ name: "login"})
    }else{
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks?task_owner='+user_id+'&task_status='+5)
          .then((res) => {
            res.data.data.forEach(function(task) {
                if(task.status_id==="5"){
                  Notify.create({
                    color: 'orange-5',
                    textColor: 'white',
                    icon: 'warning',
                    message: 'You are still enroute to task: '+task.id,
                    timeout: 10000,
                  })
                  let url = '/execute-task/'+task.id;
                  router.push({ path: url})
                }
              if(task.status_id==="8"){
                Notify.create({
                  color: 'orange-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'You are still busy with checklist for task: '+task.id,
                  timeout: 10000,
                })
                let url = '/execute-task/'+task.id;
                router.push({ path: url})
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    }
  },
  data(){
    let show_survey = false;
    let survey_done = null;
    let are_you_using_app = '';
    let why_not = '';
    let branches = [];
    let branch_str = localStorage.getItem('branch')
    let branch_object = null;
    console.log(branch_str)
    if(JSON.parse(branch_str)){
      // eslint-disable-next-line no-unused-vars
      branch_object = JSON.parse(branch_str);
    }else{
      // eslint-disable-next-line no-unused-vars
      branch_object = {
        id: 1,
        client: 'No branch'
      }
    }
    let branch = branch_object.client
    if(branch){
      console.log("Branch is "+branch);
    }
    return {
      branch,
      branches,
      are_you_using_app,
      why_not,
      survey_done,
      show_survey,
      conversations : Object,
      draw_list : 0,
    }
  },
  mounted(){
    this.getBranches();
    this.getConversations();
    this.getSurveys();
  },

  methods: {
    submitSurvey() {
      let url = process.env.VUE_APP_API_BASE_URL + '/api/cases/driver-survey/';
      let data = new FormData();
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      data.append('are_you_using_app', this.are_you_using_app);
      data.append('why_not', this.why_not);
      axios.post(url,data, config)
          .then(() => {
            this.survey_done = true;
             window.location.reload();
          })
          .catch(() => {
          }).finally(() => {
      });
    },
    getSurveys(){
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/driver-survey')
          .then((res) => {
            console.log(res);
            this.survey_done = true;
          })
          .catch(() => {
            this.survey_done = false;
          }).finally(() => {

      });
    },
    getBranches(){
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/branches')
          .then((res) => {
            let temp = res.data.data;
            temp.forEach(branch =>{
              let this_branch = { id: branch.id, client: branch.client };
              this.branches.push(this_branch);
            });
            console.log(this.branches);
            this.draw_list += 1;
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    setBranch(){
      localStorage.setItem('branch',JSON.stringify(this.branch));
      Notify.create({
        color: 'green-5',
        textColor: 'white',
        icon: 'check',
        message: 'Branch Changed successfully!',
        timeout: 10000,
      })
      window.location.reload();
    },
    getConversations(){
      let user_id = JSON.parse( localStorage.getItem('user_id') );
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/conversations?user_id='+user_id)
          .then((res) => {
            this.conversations = res.data.data;
            this.draw_list += 1;
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    deleteConversation(index){
      let url = process.env.VUE_APP_API_BASE_URL+'/api/cases/conversations/'+index;
      axios.delete(url)
          .then(() => {
             this.getConversations();
          })
          .catch(() => {
          }).finally(() => {
          });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

