<template>
  <div class="hello">
    <q-card class="my-card">
      <q-card-section>
        <q-item-label class="text-h3">
             Compose message <span v-if="this.case_id !== '0'"> about Case : {{this.case_id}}</span>
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-form
            method="post" @submit="this.sendNewMessage()"
            class="q-gutter-md">
          <q-list>
            <q-item>
              <q-item-section>
                <VueNextSelect :clear-on-select="true" :close-on-select="true" :searchable="true" :options="this.options" v-model="this.to_user"> </VueNextSelect>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-input v-model="this.reply_message" label="Message" :dense="true"></q-input>
              </q-item-section>
              <q-item-section thumbnail style="margin-right: 50px">
                <q-btn type="submit" round color="secondary" icon="navigation"></q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-form>
      </q-card-section>
    </q-card>
    <q-footer bordered class="bg-white text-primary" elevated >
      <q-form
          method="post" @submit="this.sendNewMessage()"
          class="q-gutter-md">
      </q-form>
    </q-footer>
  </div>
</template>

<script>
import {useRoute, useRouter} from 'vue-router'
import axios from "axios";
import { Notify } from 'quasar'
import NoSleep from 'nosleep.js';
import VueNextSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'

export default {
  name: 'PageInbox',
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
    const router = useRouter();
    let token = JSON.parse( localStorage.getItem('token') );
    if( !token ){
      router.push({ name: "login"})
    }
  },
  components: {
    VueNextSelect
  },
  data(){
    return {
      options : [],
      draw_list : 0,
      parameter_id : null,
      messages_marked : false,
      reply_message : null,
      case_id : 0,
      to_user: null,
      to_user_id: null,
      users : [],
    }
  },
  mounted(){
     this.getUsers();
     this.setupParameters();
  },
  computed:{
     user_id(){
       return this.$store.getters.user_id;
     }
  },
  methods: {
    setupParameters() {
      const route = useRoute()
      this.case_id = 0;
      console.log(route.params.case_id);
      if(route.params.case_id !== undefined){
        this.case_id = route.params.case_id;
      }else{
        this.case_id = 0;
      }
    },
    getUsers(){
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/users')
          .then((res) => {
            this.users = res.data.data;
            this.users.forEach((user) => {
              if(user.name!==null){
                this.options.push(user.name);
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    goToConversation(to_user_id){
      let url = '/message/'+to_user_id+'/'+this.case_id;
      window.location.href = url;
    },
    sendNewMessage(){
      console.log("Hello")
      let user_id = this.user_id;
      let subject = '';
      let message = this.reply_message;
      let message_type = 0;
      let case_id = this.case_id;
      this.users.forEach((user) => {
        if(user.name===this.to_user){
          this.to_user_id = user.id;
        }
      });
      let file_data = new FormData();
      file_data.append("action", "sendNewMessage")
      file_data.append("message",message);
      file_data.append("from_user", user_id)
      file_data.append("to_user", this.to_user_id);
      file_data.append("subject", subject);
      file_data.append("message_type", message_type);
      file_data.append("case_id", case_id);
      let file_config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }

      // eslint-disable-next-line no-unreachable
      axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/messages/', file_data, file_config)
          .then(() => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'green-5',
              textColor: 'white',
              icon: 'success',
              message: 'Message sent successfully !! '
            });
            this.reply_message = '';
            this.goToConversation(this.to_user_id);
          })
          .catch((error) => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Failed retrieving messages !! ' + error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>

