<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <q-dialog v-model="this.displayPreChecklists" persistent transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <q-form
            method="post" @submit="this.submitPreChecklists()"
            class="q-gutter-md">
          <div v-for="(checkItem,index) in this.preChecklists" :key="index">
            <q-card-section>
              <div class="text-h6">Checklist {{this.preForms[index][0].name}}</div>
            </q-card-section>
            <q-card-section v-for="(formField,form_index) in checkItem" :key="form_index">
              <label>{{formField['desc']}}</label>
              <div v-if="( parseInt(formField.options.multi) ===1)">
                <q-select
                    filled
                    v-model="formField.answer"
                    multiple
                    emit-value
                    :options="formField.options.options"
                    :label="formField['desc']"
                ></q-select>
              </div>
              <div v-if="( parseInt(formField.options.multi) ===0 ) && (formField['type']==='choices')">

                <q-select style="margin-bottom: 10px" v-model="formField.answer" :options="formField.options.options">
                </q-select>
              </div>
              <div v-if="(formField['type']==='boolean')">
                <q-select style="margin-bottom: 10px" v-model="formField.answer" :options="[formField.options.false,formField.options.true]">
                </q-select>
                <div v-if="formField.answer===formField.options.false">
                  <q-btn v-if="formField.answer===formField.options.false" @click="this.takeSinglePhoto(formField)" color="primary" round  icon="photo_camera"></q-btn>
                  <q-item-label style="margin-top: 20px">Enter fault description</q-item-label>
                  <q-input v-model="formField.answer_reason" type="text"></q-input>
                  <q-img
                      :key="formField.image" v-if="formField.image"
                      :src="formField.image"
                      style="margin-top: 20px; height: 140px; max-width: 150px"
                  >
                    <template v-slot:loading>
                      <q-spinner-gears color="white"></q-spinner-gears>
                    </template>
                  </q-img>
                </div>
              </div>
              <div v-if="(formField['type']==='text')">
                <q-input type="text" style="margin-bottom: 10px" v-model="formField.answer">
                </q-input>
              </div>
              <div v-if="(formField['type']==='datetime')">
                <q-input type="date" mask="YYYY-MM-DD" style="margin-bottom: 10px" v-model="formField.date">
                </q-input>
                <q-input type="time" style="margin-bottom: 10px" v-model="formField.time">
                </q-input>
              </div>
              <div v-if="(formField['type']==='number')">
                <q-input type="number" :rules="[ val => val >= 0 || 'Please use a non negative number' ]" style="margin-bottom: 10px" v-model="formField.answer">
                </q-input>
              </div>
              <div v-if="(formField['type']==='signature')">
                <SignatureCard :item_id="formField['id']"></SignatureCard>
              </div>
              <div v-if="(parseInt(formField.options.photo_required)===1)">
                <q-item-label>A Photo is required for this Item</q-item-label>
                <q-btn style="margin-top: 10px" @click="this.takeSinglePhoto(formField)" color="primary" round  icon="photo_camera"></q-btn>
                <q-img
                    :key="formField.image" v-if="formField.image"
                    :src="formField.image"
                    style="height: 140px; max-width: 150px"
                >
                  <template v-slot:loading>
                    <q-spinner-gears color="white"></q-spinner-gears>
                  </template>
                </q-img>
              </div>
            </q-card-section>
          </div>

        </q-form>
      </q-card-section>
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Done" @click="submitPreChecklists()" type="submit" color="primary"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="this.displayPauseChecklists" persistent transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <q-form
            method="post" @submit="this.submitPauseChecklists()"
            class="q-gutter-md">
          <div v-for="(checkItem,index) in this.preChecklists" :key="index">
            <q-card-section>
              <div class="text-h6">Checklist {{this.preForms[index][0].name}}</div>
            </q-card-section>
            <q-card-section v-for="(formField,form_index) in checkItem" :key="form_index">
              <label>{{formField['desc']}}</label>
              <div v-if="( parseInt(formField.options.multi) ===1)">
                <q-select
                    filled
                    v-model="formField.answer"
                    multiple
                    emit-value
                    :options="formField.options.options"
                    :label="formField['desc']"
                ></q-select>
              </div>
              <div v-if="( parseInt(formField.options.multi) ===0 ) && (formField['type']==='choices')">

                <q-select style="margin-bottom: 10px" v-model="formField.answer" :options="formField.options.options">
                </q-select>
              </div>
              <div v-if="(formField['type']==='boolean')">
                <q-select style="margin-bottom: 10px" v-model="formField.answer" :options="[formField.options.false,formField.options.true]">
                </q-select>
                <div v-if="formField.answer===formField.options.false">
                  <q-btn v-if="formField.answer===formField.options.false" @click="this.takeSinglePhoto(formField)" color="primary" round  icon="photo_camera"></q-btn>
                  <q-item-label style="margin-top: 20px">Enter fault description</q-item-label>
                  <q-input v-model="formField.answer_reason" type="text"></q-input>
                  <q-img
                      :key="formField.image" v-if="formField.image"
                      :src="formField.image"
                      style="margin-top: 20px; height: 140px; max-width: 150px"
                  >
                    <template v-slot:loading>
                      <q-spinner-gears color="white"></q-spinner-gears>
                    </template>
                  </q-img>
                </div>
              </div>
              <div v-if="(formField['type']==='text')">
                <q-input type="text" style="margin-bottom: 10px" v-model="formField.answer">
                </q-input>
              </div>
              <div v-if="(formField['type']==='datetime')">
                <q-input type="date" mask="YYYY-MM-DD" style="margin-bottom: 10px" v-model="formField.date">
                </q-input>
                <q-input type="time" style="margin-bottom: 10px" v-model="formField.time">
                </q-input>
              </div>
              <div v-if="(formField['type']==='number')">
                <q-input type="number" :rules="[ val => val >= 0 || 'Please use a non negative number' ]" style="margin-bottom: 10px" v-model="formField.answer">
                </q-input>
              </div>
              <div v-if="(formField['type']==='signature')">
                <SignatureCard :item_id="formField['id']"></SignatureCard>
              </div>
              <div v-if="(parseInt(formField.options.photo_required)===1)">
                <q-item-label>A Photo is required for this Item</q-item-label>
                <q-btn style="margin-top: 10px" @click="this.takeSinglePhoto(formField)" color="primary" round  icon="photo_camera"></q-btn>
                <q-img
                    :key="formField.image" v-if="formField.image"
                    :src="formField.image"
                    style="height: 140px; max-width: 150px"
                >
                  <template v-slot:loading>
                    <q-spinner-gears color="white"></q-spinner-gears>
                  </template>
                </q-img>
              </div>
            </q-card-section>
          </div>
        </q-form>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Done" @click="submitPauseChecklists()" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <q-dialog v-model="this.displayPauseModal" persistent transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <q-form
            method="post" @submit="this.submitPauseReason()"
            class="q-gutter-md">
          <div>
            <q-card-section>
              <div class="text-h6">Paused</div>
            </q-card-section>
            <q-card-section>
              <q-option-group
                  :options="this.pauseReasons"
                  type="radio"
                  v-model="this.lastPauseReason"
              ></q-option-group>
            </q-card-section>
          </div>
        </q-form>
      </q-card-section>
      <q-card-section v-if="this.lastPauseReason==='Breakdown'">
        <q-item-label>Breakdown Photos</q-item-label>
        <q-btn  @click="this.takePhoto(this.pausePhotos)" color="primary" round  icon="photo_camera"></q-btn>
      </q-card-section>
      <q-card-section v-for="(image,index) in this.pausePhotos" :key="index">
        <q-img
            :key="image" v-if="image"
            :src="image"
            style="height: 140px; max-width: 150px"
        >
          <template v-slot:loading>
            <q-spinner-gears color="white"></q-spinner-gears>
          </template>
        </q-img>

      </q-card-section>
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Done" @click="submitPauseReason()" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <q-dialog v-model="this.displayStartOdoModal" persistent transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <q-form
            method="post" @submit="this.submitOdoMeter('start')"
            class="q-gutter-md">
          <div>
            <q-card-section>
              <div class="text-h6">Odometer</div>
            </q-card-section>
            <q-card-section>
              <q-input
                  class="q-mt-md"
                  filled
                  v-model="this.returned_task.odo_start"
                  label="Odometer Reading"
                  type="text"
              />
            </q-card-section>
            <q-btn  @click="this.takePhoto(this.returned_task)" color="primary" round  icon="photo_camera"></q-btn>
            <q-img
                :key="returned_task.image" v-if="returned_task.image"
                :src="returned_task.image"
                style="height: 140px; max-width: 150px"
            >
              <template v-slot:loading>
                <q-spinner-gears color="white"></q-spinner-gears>
              </template>
            </q-img>
          </div>
        </q-form>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Done" @click="submitOdoMeter('start')" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <q-dialog v-model="this.displayEndOdoModal" persistent transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <q-form
            method="post" @submit="this.submitOdoMeter('end')"
            class="q-gutter-md">
          <div>
            <q-card-section>
              <div class="text-h6">Odometer</div>
            </q-card-section>
            <q-card-section>
              <q-input
                  class="q-mt-md"
                  filled
                  v-model="this.returned_task.odo_end"
                  label="Odometer Reading"
                  type="text"
              />
            </q-card-section>
            <q-btn  @click="this.takePhoto(this.returned_task)" color="primary" round  icon="photo_camera"></q-btn>
            <q-img
                :key="returned_task.image" v-if="returned_task.image"
                :src="returned_task.image"
                style="height: 140px; max-width: 150px"
            >
              <template v-slot:loading>
                <q-spinner-gears color="white"></q-spinner-gears>
              </template>
            </q-img>
          </div>
        </q-form>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Done" @click="submitOdoMeter('end')" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <q-dialog v-model="this.displayCustomerSignatureModal" persistent transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <div>
          <q-card-section>
            <div class="text-h6">Sign Off Delivery Form</div>
          </q-card-section>
          <q-card-section v-if="this.customer_signature===null">
              <SignatureCard :item_id="'sign_off'"></SignatureCard>
          </q-card-section>
          <q-card-section v-if="this.customer_signature!==null">
            <q-img
                :key="this.customer_signature" v-if="this.customer_signature"
                :src="this.customer_signature"
                style="height: 140px; max-width: 150px"
            >
              <template v-slot:loading>
                <q-spinner-gears color="black"></q-spinner-gears>
              </template>
            </q-img>
          </q-card-section>
          <q-form method="post" @submit="this.submitCustomerSignature()"
                  class="q-gutter-md">
            <q-card-section>
              <q-item-label>Customer Satisfaction Rating</q-item-label>
              <q-slider
                  class="q-mt-xl"
                  v-model="this.customer_satisfaction"
                  color="deep-orange"
                  markers
                  :marker-labels="fnMarkerLabel"
                  :min="0"
                  :max="10"
              ></q-slider>
            </q-card-section>
          </q-form>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Complete" @click="this.submitCustomerSignature()" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <q-dialog v-model="this.displayDeliveryModal" persistent transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <div>
          <q-form>
            <q-card-section>
              <div class="text-h6">Sign Off Delivery Form</div>
            </q-card-section>
            <q-card-section>
              <q-item-label>Delivery Note Photos</q-item-label>
              <q-btn  @click="this.takePhoto(this.delivery_notes)" color="primary" round  icon="photo_camera"></q-btn>
            </q-card-section>
            <q-card-section v-for="(image,index) in this.delivery_notes" :key="index">
              <q-img
                  :key="image" v-if="image"
                  :src="image"
                  style="height: 140px; max-width: 150px"
              >
                <template v-slot:loading>
                  <q-spinner-gears color="white"></q-spinner-gears>
                </template>
              </q-img>

            </q-card-section>
            <q-card-section>
              <q-input
                  class="q-mt-md"
                  v-model="this.returned_task.delivery_code"
                  label="Delivery Note Nr:"
                  type="text"
              />
            </q-card-section>
            <q-card-section>
              <q-item-label>Are there returns on this delivery?</q-item-label>
              <q-select style="margin-bottom: 10px" v-model="this.returned_task.returns_y_n" :options="['Yes','No']"/>
            </q-card-section>
            <div v-if="this.returned_task.returns_y_n==='Yes'" >
              <q-card-section  v-if="this.returned_task.returns_y_n==='Yes'" >
                <q-item-label>Reason for return?</q-item-label>
                <q-select style="margin-bottom: 10px" v-model="this.returned_task.possible_reason_for_returns" :options="['Damaged','Not ordered','Incorrect order','Other']"/>
              </q-card-section>
              <q-card-section v-if="this.returned_task.returns_y_n==='Yes'" >
                <q-input
                    class="q-mt-md"
                    v-model="this.returned_task.reason_for_returns"
                    label="Return Note :"
                    type="text"
                />
              </q-card-section>
              <q-card-section v-if="this.returned_task.returns_y_n==='Yes'">
                <q-item-label>Delivery Return Photos</q-item-label>
                <q-btn  @click="this.takePhoto(this.delivery_returns)" color="primary" round  icon="photo_camera"></q-btn>
              </q-card-section>
              <q-card-section v-for="(image,index) in this.delivery_returns" :key="index">
                <q-img
                    :key="image" v-if="image"
                    :src="image"
                    style="height: 140px; max-width: 150px"
                >
                  <template v-slot:loading>
                    <q-spinner-gears color="white"></q-spinner-gears>
                  </template>
                </q-img>

              </q-card-section>
            </div>
          </q-form>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Complete" @click="completeDelivery()" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <q-dialog v-model="this.displayAwaitingModal" persistent transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <div>
          <q-form>
            <q-card-section>
              <div class="text-h6">Have you received your next instructions?</div>
            </q-card-section>
            <q-card-section>
              <q-item-label>Have you received your next instructions?</q-item-label>
              <q-select style="margin-bottom: 10px" v-model="this.next_received" :options="['Yes','No, Awaiting for instructions from OPS']"/>
            </q-card-section>
          </q-form>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Complete" @click="submitAwaiting()" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <q-dialog @hide="this.cancelTransfer()" v-model="this.displayTransferModal"  transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <div>
          <q-form
              method="post" @submit="this.transferTrip()"
              class="q-gutter-md">
            <q-list>
              <q-item>
                <q-item-section>
                  Transfer Trip to another Driver
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section class="text-grey-14">
                  <q-item-label>
                    Select Driver
                  </q-item-label>
                  <VueNextSelect  :clear-on-select="true" :close-on-select="true" :searchable="true" :options="this.options" v-model="this.transferUser"> </VueNextSelect>
                </q-item-section>
              </q-item>
            </q-list>
          </q-form>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Transfer" @click="transferTrip()" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>

  <div  id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 100%">
      <div>
        <q-card class="my-card bg-gray text-gray">
          <q-card-section>
            <q-item v-ripple>
              <q-item-section class="text-h5">{{this.returned_task.id}} - {{this.returned_task.title}} </q-item-section>
              <q-item-section avatar>
                <q-icon color="primary" name="settings"></q-icon>
              </q-item-section>
              <q-item-section avatar>
                <q-btn round color="black" @click="this.initiateTransfer()" icon="move_down"></q-btn>
              </q-item-section>
            </q-item>

            <q-item v-if='this.app_status === "enroute"'>
              <q-spinner-dots
                  color="primary"
                  size="2em"
              ></q-spinner-dots>
              &nbsp;  &nbsp; <b> Approx Speed: </b> {{this.speed}} Km/h &nbsp; &nbsp;
              <q-spinner-dots
                  color="primary"
                  size="2em"
              ></q-spinner-dots>
              &nbsp;  &nbsp; <b>Approx Distance: </b> {{ parseFloat(this.distance_away).toFixed(2) }} km  &nbsp;  &nbsp;
              <q-spinner-dots
                  color="primary"
                  size="2em"
              ></q-spinner-dots>
            </q-item>
          </q-card-section>


          <q-separator dark></q-separator>
          <q-list  bordered>
            <q-item>
              <q-item-section v-if='this.app_status === "new"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn @click="this.updateTaskStatus('4')" color="secondary" icon="check" label="Accept Task"/>
                </div>
              </q-item-section>
              <q-item-section v-if='this.app_status === "accepted"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn @click="this.postOdometer()" color="secondary" icon="check_box" label="Post Odo Readings"/>
                </div>
              </q-item-section>
              <q-item-section v-if='this.app_status === "pre-check-lists-complete"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn @click="this.postOdometer()" color="secondary" icon="speed" label="Post Odo Readings"/>
                </div>
              </q-item-section>
              <q-item-section v-if='this.app_status === "post-odometer"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn @click="this.updateTaskStatus('5')" color="secondary" icon="local_shipping" label="Start Driving"/>
                </div>
              </q-item-section>
              <q-item-section v-if='this.app_status === "enroute"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn color="secondary" icon="commute" label="Enroute"/>
                </div>
              </q-item-section>
              <q-item-section v-if='this.app_status === "awaiting-offload"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn @click="this.updateTaskStatus('14')" color="secondary" icon="local_shipping" label="Sign Off"/>
                </div>
              </q-item-section>
              <q-item-section v-if='this.app_status === "post-odometer-onsite"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn @click="this.updateTaskStatus('17')" color="secondary" icon="assignment_add" label="Awaiting Onload/Offload"/>
                </div>
              </q-item-section>
              <q-item-section v-if='this.app_status === "paused"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn @click="this.updateTaskStatus('5')" color="secondary" icon="local_shipping" label="Start Driving"/>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
        <q-card>
          <q-item-section avatar>
            <div class="q-pa-md q-gutter-sm" id="sidebar">
            </div>
          </q-item-section>
        </q-card>
        <div class="q-pa-md q-list--bordered q-item q-item-type row flex my-card bg-gray text-gray" id="map" style="height: 400px">

        </div>
        <q-card :key="this.case_status">
          <q-list>
            <q-separator dark>

            </q-separator>
            <q-card-section>
              <q-item>
                <q-item-section class="text-h6">Task Address:</q-item-section>
              </q-item>
              <q-item>
                {{this.returned_task.address}}
              </q-item>
              <q-item>
                <q-item-section class="text-h6">Task due date: </q-item-section>
              </q-item>
              <q-item>
                {{this.returned_task.due_date}}
              </q-item>
              <q-item>
                <q-item-section class="text-h6">Task Description:</q-item-section>
              </q-item>
              <q-item>
                <q-item-section>{{ this.returned_task.description }}</q-item-section>
              </q-item>
            </q-card-section>
          </q-list>
        </q-card>
      </div>
    </div>
  </div>

</template>

<style>
</style>

<script>
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import {useQuasar} from "quasar";
import { Notify } from 'quasar'
import { Geolocation } from '@capacitor/geolocation'
import NoSleep from 'nosleep.js';
import { Camera, CameraResultType } from "@capacitor/camera";
import { ref } from 'vue';
import VueNextSelect from 'vue-next-select'
import SignatureCard from "@/components/SignatureCard.vue";
import 'vue-next-select/dist/index.min.css'
// eslint-disable-next-line no-unused-vars
import VueTextToSpeech from 'vue-text-to-speech';

export default {
  name: 'ExecuteTaskPage',
  setup(){
    const router = useRouter()
    const model = ref(2)
    let token = JSON.parse( localStorage.getItem('token') );
    if( token ){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }else{
      router.push({ name: "login"})
    }

    const noSleep = new NoSleep();
    noSleep.enable();
    return {
      model,
      fnMarkerLabel: val => `${10 * val}%`,
    }
  },
  data(){
    return {
      next_received: false,
      pauseReasons : [],
      pausePhotos: [],
      displayTransferModal : false,
      displayAwaitingModal : false,
      transferUser : null,
      transferUser_id : null,
      lastPauseReason : "Unknown",
      returned_task : Object,
      delivery_notes: [],
      delivery_returns: [],
      counter_delivery_images : 0,
      customer_signature : null,
      longitude : null,
      latitude : null,
      speed : 0,
      speed_interval_counter : 0,
      paused : 0,
      position :  1,
      customer_satisfaction: null,
      case_status : 0,
      case_loaded : 0,
      preForms : Object,
      parameter_id : null,
      displayStartOdoModal: false,
      displayEndOdoModal : false,
      displayDeliveryModal : false,
      displayPauseModal : false,
      displayPreChecklists : false,
      displayCustomerSignatureModal : false,
      displayPauseChecklists : false,
      speed_1min : 5,
      speed_2min : 5,
      speed_3min : 5,
      speed_4min : 5,
      speed_5min : 5,
      speed_10sec : 5,
      speed_20sec : 5,
      speed_30sec : 5,
      speed_40sec : 5,
      speed_50sec : 5,
      speed_60sec : 5,
      app_status : null,
      map : null,
      preChecklists : [],
      pauseChecklists : [],
      image : null,
      map_drawn : false,
      markers_drawn : false,
      distance_away : 0,
      currentMarker : null,
      destinationMarker : null,
      truckMarker : null,
      currentStep : 0,
      directionsPanel : null,
      directionsToRead : null,
      lastDirections : null,
      userInput: '',
      pitch: 1,
      rate: 1,
      synth: window.speechSynthesis,
      validation: false,
      options : [],

    }
  },
  mounted(){
    this.setupParameters();
    this.getData();
    this.getPauseReasons();
    this.getUsers();
    this.getPauseChecklists();
    let app = this;
    app.getCurrentPosition();
    setInterval(function () {
      app.calculateDistance();
    }, 5000);
    setInterval(function () {
      app.initMap();
    }, 500);
    setInterval(function () {
      app.refreshMarkers();
    }, 500);

  },
  components:{
    VueNextSelect,
    SignatureCard
  },
  methods : {
    submitAwaiting(){
      axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/users/', {next_received: this.next_received})
          .then(() => {
            Notify.create({
              color: 'green-5',
              textColor: 'white',
              icon: 'success',
              message: 'Awaiting status updated !! '
            });
          })
          .catch(() => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Failed updating awaiting status !! '
            })
          }).finally(() => {
            this.goToHome();
      });
    },
    goToHome(){
      let url = '/';
      window.location.href = url;
    },
    setupParameters() {
      const route = useRoute()
      this.parameter_id = route.params.id;
    },
    initiateTransfer(){
      this.displayTransferModal = true;
    },
    cancelTransfer(){
      this.displayTransferModal = false;
    },
    transferTrip(){
        let user_id = JSON.parse(localStorage.getItem('user_id'));
        this.users.forEach((user) => {
          if(user.name===this.transferUser){
            this.transferUser_id = user.id;
          }
        });
        let data = {
          "action": "transferTask",
          "id": this.returned_task.id,
          "to_user" : this.transferUser_id,
          "status": status,
          "user_id": user_id
        }
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }

      axios.put(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks/' + this.returned_task.id, data, config)
            .then(() => {
              Notify.create({
                color: 'green-5',
                textColor: 'white',
                icon: 'success',
                message: 'Trip sent transferred !! '
              });
              this.goToHome();
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Failed transferring trip !! ' + error
              })
            }).finally(() => {
          //Perform action in always
        });
    },
    
    getUsers(){
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/users')
          .then((res) => {
            this.users = res.data.data;
            this.users.forEach((user) => {
              if(user.name!==null){
                this.options.push(user.name);
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    refreshMarkers() {
      this.getCurrentPosition();
      let loadDirections = true;
      if (this.markers_drawn !== false) {
        loadDirections = false;
      }
      let latitude = JSON.parse(localStorage.getItem('latitude'));
      let longitude = JSON.parse(localStorage.getItem('longitude'));
      this.currentMarker = {
        position: {
          lat: parseFloat(latitude), lng: parseFloat(longitude)
        },
      }
      this.destinationMarker = {
        position: {
          lat: parseFloat(this.returned_task.gps_lat), lng: parseFloat(this.returned_task.gps_lng)
        },
      }

      if (this.currentMarker.position.latitude === null) {
        loadDirections = false;
      }
      if (this.destinationMarker.position.latitude === null) {
        loadDirections = false;
      }

      if (this.truckMarker === null) {
        // eslint-disable-next-line no-undef
        this.truckMarker = new google.maps.Marker({
          position: this.currentMarker.position,
          icon: "/img/icons/truck.png",
          map: this.map,
        });
      } else {
        this.truckMarker.setPosition(this.currentMarker.position);
      }

      if (loadDirections === true) {
          this.map.setCenter({ lat: this.latitude, lng: this.longitude });
      }
    },
    speak() {
      if (this.synth.speaking) {
        console.error('speechSynthesis.speaking');
        return;
      }
      if (this.userInput !== '') {
        this.validation = false;
        let sInstance = new SpeechSynthesisUtterance(this.userInput);
        // eslint-disable-next-line no-unused-vars
        sInstance.onend = function (event) {
          console.log('SpeechSynthesisUtterance.onend');
        }
        // eslint-disable-next-line no-unused-vars
        sInstance.onerror = function (event) {
          console.error(event);
        }
        sInstance.pitch = this.pitch;
        sInstance.rate = this.rate;
        this.synth.speak(sInstance);
      } else {
        this.validation = true;
      }
      this.userInput = '';
    },

    initMap() {
      if(this.map_drawn===true){
        return;
      }
      // eslint-disable-next-line no-undef
      let latitude = JSON.parse(localStorage.getItem('latitude'));
      let longitude = JSON.parse(localStorage.getItem('longitude'));
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        zoom: 11,
      });
      this.map_drawn = true;
      this.setupMarkers();
    },
    setupMarkers(){
      let latitude = JSON.parse(localStorage.getItem('latitude'));
      let longitude = JSON.parse(localStorage.getItem('longitude'));
      this.currentMarker = {
        position: {
          lat: parseFloat(latitude), lng: parseFloat(longitude)
        },
      }
      this.destinationMarker = {
        position: {
          lat: parseFloat(this.returned_task.gps_lat), lng: parseFloat(this.returned_task.gps_lng)
        },
      }

      if(this.truckMarker ===null){
        // eslint-disable-next-line no-undef
        this.truckMarker = new google.maps.Marker({
          position: this.currentMarker.position,
          icon:   "/img/icons/truck.png",
          map: this.map,
        });
      }

    },
    getPauseReasons(){
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/pause-reasons/')
          .then((res) => {
            let reasons_array = [];
            res.data.data.forEach((reason) => {
              let reasonObject = {
                "label": reason,
                "value" : reason,
              }
              reasons_array.push(reasonObject);
            });
            this.pauseReasons = reasons_array;
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
      });
    },
    async getData() {
      const $q = useQuasar();
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks/' + this.parameter_id)
          .then((res) => {
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Tasks Retrieved !! '
            })
            this.returned_task = res.data.data[0];
            this.checkAppStatus();
            this.getCurrentPosition();
          })
          .catch((error) => {
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: ' + error
            })
          }).finally(() => {
        this.forceRefresh();
        //Perform action in always
      });
    },
    async refreshData() {
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks/' + this.parameter_id)
          .then((res) => {
            Notify.create({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Tasks Refreshed !! '
            })
            this.returned_task = res.data.data[0];
            this.forceRefresh();
          })
          .catch((error) => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error accepting tasks: ' + error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
    updateSpeeds(){
      this.speed_interval_counter = 0;
      this.speed_5min = 5;
      this.speed_4min = 5;
      this.speed_3min = 5;
      this.speed_2min = 5;
      this.speed_1min = 5;
    },
    getPreChecklists() {
      if (this.displayPreChecklists===false) {
        axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/forms/' + this.parameter_id)
            .then((res) => {
              this.preForms = res.data.data;
            })
            .catch(() => {
            }).finally(() => {
          //Perform action in always
        });
        axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/form-fields/' + this.parameter_id + '?type=pre')
            .then((res) => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Checklists pulled successfully !! '
              })
              this.preChecklists = res.data.data;
              this.displayPreChecklists = true;
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Error getting checklists: ' + error
              })
            }).finally(() => {
          //Perform action in always
        });
      }
    },
    getPauseChecklists() {
        axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/form-fields/' + this.parameter_id + '?type=pause')
            .then((res) => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Pause Checklists pulled successfully !! '
              })
              this.pauseChecklists = res.data.data;
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Error getting checklists: ' + error
              })
            }).finally(() => {
          //Perform action in always
        });
    },
    getCurrentPosition() {
      let geoId;
      if (Geolocation.checkPermissions()) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true
        }).then(newPosition => {
          this.speed_interval_counter = this.speed_interval_counter + 5;
          this.speed = Math.round(newPosition.coords.speed * 3.6);
          localStorage.setItem('latitude', JSON.stringify(newPosition.coords.latitude));
          this.latitude = newPosition.coords.latitude;
          localStorage.setItem('longitude', JSON.stringify(newPosition.coords.longitude));
          this.longitude = newPosition.coords.longitude;
          if (this.app_status==="enroute") {
            if (this.speed_interval_counter===10) {
                this.speed_10sec = this.speed;
                this.speed_20sec = this.speed_10sec;
                this.speed_30sec = this.speed_20sec;
                this.speed_40sec = this.speed_30sec;
                this.speed_50sec = this.speed_40sec;
                this.speed_60sec = this.speed_50sec;
            }
            if (this.speed_interval_counter===60) {
              console.log("Updating minute intervals");
              if(this.distance_away < 0.3){
                this.updateTaskStatus('6')
              }
              this.speed_5min = this.speed_4min;
              this.speed_4min = this.speed_3min;
              this.speed_3min = this.speed_2min;
              this.speed_2min = this.speed_1min;
              this.speed_1min = (this.speed_10sec + this.speed_20sec + this.speed_30sec + this.speed_40sec + this.speed_50sec + this.speed_60sec) / 6;
              this.speed_interval_counter = 0;
            }
          }
        })
      } else {
        Geolocation.requestPermissions();
      }
      this.checkMoving();
      return {
        geoId
      }
    },
    submitPreChecklists() {
      let user_id = JSON.parse(localStorage.getItem('user_id'));
      let cases = [];
      let data = new FormData();
      let exception = 0;
      data.append("action", "updateStatus")
      data.append("task_id", this.returned_task.id)
      data.append("user_id", user_id)
      Object.keys(this.preChecklists).forEach(key => {
        this.preChecklists[key].forEach(thing => {
          if(thing.type==='datetime'){
            if(thing.time === undefined){
              exception = 1;
            }
            if(thing.date === undefined){
              exception =1;
            }
            if(exception === 1){
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Please fill in all fields! ' + thing.desc + ' is not filled'
              })
            }else{
              thing.answer = thing.date + ' ' + thing.time;
            }
          }
          if ( (thing.answer === undefined) && (thing.type !== 'signature')) {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Please fill in all fields! ' + thing.desc + ' is not filled'
            })
            exception = 1;
          }
          if (thing.options.photo_required == '1' && thing.image === undefined) {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Please upload photo in  required fields! ' + thing.desc + ' has no photo!'
            })
            exception = 1;
          }
          if (thing.type === 'signature'){
            let signature_base64 =  localStorage.getItem(thing.id+'_signature_base64') ;
            if(signature_base64=== undefined){
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Please sign the form'
              })
              exception = 1;
            }else{
              thing.answer = 'Signature '+thing.desc;
              thing.image = signature_base64;
            }
          }
          if ((thing.answer===thing.options.false) && (thing.options.negatives?.negative_case=='Yes') ) {
            cases.push(thing);
          }
        });
      });

      if(exception === 0){
        Object.keys(this.preChecklists).forEach(key => {
          this.preChecklists[key].forEach(thing => {
            if(thing.image !== undefined){
              fetch(thing.image)
                  .then((res) => res.blob())
                  .then((myBlob) => {
                    let file_config = {
                      header: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                    let file_data = new FormData();
                    file_data.append("action", "uploadFileTask")
                    file_data.append("file_note", thing.desc)
                    file_data.append("task_id", this.returned_task.id)
                    file_data.append("desired_file_name", thing.id)
                    file_data.append("user_id", user_id)
                    file_data.append('file', myBlob, 'file');
                    axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                        .then(() => {
                          Notify.create({
                            color: 'green-4',
                            textColor: 'white',
                            icon: 'cloud_done',
                            message: 'File Uploaded'
                          })
                        })
                        .catch((error) => {
                          Notify.create({
                            color: 'red-5',
                            textColor: 'white',
                            icon: 'warning',
                            message: 'File FAILED: ' + error
                          })
                          exception = 1;
                        }).finally(() => {
                      //Perform action in always
                    });
                  });
            }
            data.append('field_' + thing.id, JSON.stringify(thing));
          });
        });
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/form-fields/', data, config)
            .then(() => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Checklist saved!'
              })
              this.app_status = "Post-Check-In";
              localStorage.setItem('app_status', JSON.stringify(this.app_status));
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Checklist FAILED: ' + error
              })
              exception = 1;
            }).finally(() => {
          //Perform action in always
        });

        cases.forEach(index => {
          let config = {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          }
          let data = new FormData();
          console.log("Submitting case");
          console.log(index);
          if("company" in index.options.negatives){
            data.append("company", index.options.negatives.company)
          }else{
            data.append("company", null)
          }
          if("department" in index.options.negatives){
            data.append("department", index.options.negatives.department)
          }else{
            data.append("department", null)
          }
          if("case_type" in index.options.negatives){
            data.append("case_type", index.options.negatives.case_type)
          }else{
            data.append("case_type", null)
          }
          if("case_sub_type" in index.options.negatives){
            data.append("case_sub_type", index.options.negatives.case_sub_type)
          }else{
            data.append("case_sub_type", null)
          }
          if("case_sub_sub_type" in index.options.negatives){
            data.append("case_sub_sub_type", index.options.negatives.case_sub_sub_type)
          }else{
            data.append("case_sub_sub_type", null)
          }

          let user_id = JSON.parse(localStorage.getItem('user_id'));
          data.append("action", "updatePause")
          data.append("task_id", this.returned_task.id)
          data.append("user_id", user_id)
          data.append("address", index.address)
          data.append('description','Checklist Failed'+index.label+'-'+index.answer);
          data.append('desc','Checklist Failed'+index.label+'-'+index.answer);
          data.append('longitude',this.longitude);
          data.append('latitude',this.latitude);
          axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/cases/', data, config)
              .then((res) => {
                Notify.create({
                  color: 'green-4',
                  textColor: 'white',
                  icon: 'cloud_done',
                  message: 'Case created!'
                })
                if(res.data.data.caseID !== undefined){
                  let case_id = res.data.data.caseID;
                  fetch(index.image)
                      .then((res) => res.blob())
                      .then((myBlob) => {
                        let file_config = {
                          header: {
                            'Content-Type': 'multipart/form-data'
                          }
                        }
                        let file_data = new FormData();
                        file_data.append("action", "uploadFileCase")
                        file_data.append("file_note","checklist_failed"+'_'+case_id)
                        file_data.append("case_id", case_id);
                        file_data.append("desired_file_name", "checklist_failed"+'_'+case_id)
                        file_data.append("user_id", user_id)
                        file_data.append('file',myBlob,'file');
                        axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                            .then(() => {
                              Notify.create({
                                color: 'green-4',
                                textColor: 'white',
                                icon: 'cloud_done',
                                message: 'File Uploaded'
                              })
                            })
                            .catch((error) => {
                              exception = 1;
                              Notify.create({
                                color: 'red-5',
                                textColor: 'white',
                                icon: 'warning',
                                message: 'File FAILED: ' + error
                              })
                            }).finally(() => {
                          //Perform action in always
                        });
                      });
                }

              })
              .catch((error) => {
                Notify.create({
                  color: 'red-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'Checklist FAILED: ' + error
                })
                exception =1;
              }).finally(() => {
            //Perform action in always
          });
        });

        if(exception === 0){
          this.hideAllModals();
          this.updateTaskStatus(9);
        }

      }
    },
    submitPauseChecklists() {
      let user_id = JSON.parse(localStorage.getItem('user_id'));
      let cases = [];
      let data = new FormData();
      let exception = 0;
      data.append("action", "updateStatus")
      data.append("task_id", this.returned_task.id)
      data.append("user_id", user_id)
      Object.keys(this.preChecklists).forEach(key => {
        this.preChecklists[key].forEach(thing => {
          if(thing.type==='datetime'){
            if(thing.time === undefined){
              exception = 1;
            }
            if(thing.date === undefined){
              exception =1;
            }
            if(exception === 1){
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Please fill in all fields! ' + thing.desc + ' is not filled'
              })
            }else{
              thing.answer = thing.date + ' ' + thing.time;
            }
          }
          if ( (thing.answer === undefined) && (thing.type !== 'signature')) {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Please fill in all fields! ' + thing.desc + ' is not filled'
            })
            exception = 1;
          }
          if (thing.options.photo_required == '1' && thing.image === undefined) {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Please upload photo in  required fields! ' + thing.desc + ' has no photo!'
            })
            exception = 1;
          }
          if (thing.type === 'signature'){
            let signature_base64 =  localStorage.getItem(thing.id+'_signature_base64') ;
            if(signature_base64=== undefined){
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Please sign the form'
              })
              exception = 1;
            }else{
              thing.answer = 'Signature '+thing.desc;
              thing.image = signature_base64;
            }
          }
          if ((thing.answer===thing.options.false) && (thing.options.negatives?.negative_case=='Yes') ) {
            cases.push(thing);
          }
        });
      });
      if(exception === 0){
        Object.keys(this.preChecklists).forEach(key => {
          this.preChecklists[key].forEach(thing => {
            if(thing.image !== undefined){
              fetch(thing.image)
                  .then((res) => res.blob())
                  .then((myBlob) => {
                    let file_config = {
                      header: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                    let file_data = new FormData();
                    file_data.append("action", "uploadFileTask")
                    file_data.append("file_note", thing.desc)
                    file_data.append("task_id", this.returned_task.id)
                    file_data.append("desired_file_name", thing.id)
                    file_data.append("user_id", user_id)
                    file_data.append('file', myBlob, 'file');
                    axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                        .then(() => {
                          Notify.create({
                            color: 'green-4',
                            textColor: 'white',
                            icon: 'cloud_done',
                            message: 'File Uploaded'
                          })
                        })
                        .catch((error) => {
                          Notify.create({
                            color: 'red-5',
                            textColor: 'white',
                            icon: 'warning',
                            message: 'File FAILED: ' + error
                          })
                          exception = 1;
                        }).finally(() => {
                      //Perform action in always
                    });
                  });
            }
            data.append('field_' + thing.id, JSON.stringify(thing));
          });
        });
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/form-fields/', data, config)
            .then(() => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Checklist saved!'
              })
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Checklist FAILED: ' + error
              })
              exception = 1;
            }).finally(() => {
          //Perform action in always
        });

        cases.forEach(index => {
          let config = {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          }
          let data = new FormData();
          console.log("Submitting case");
          console.log(index);
          if("company" in index.options.negatives){
            data.append("company", index.options.negatives.company)
          }else{
            data.append("company", null)
          }
          if("department" in index.options.negatives){
            data.append("department", index.options.negatives.department)
          }else{
            data.append("department", null)
          }
          if("case_type" in index.options.negatives){
            data.append("case_type", index.options.negatives.case_type)
          }else{
            data.append("case_type", null)
          }
          if("case_sub_type" in index.options.negatives){
            data.append("case_sub_type", index.options.negatives.case_sub_type)
          }else{
            data.append("case_sub_type", null)
          }
          if("case_sub_sub_type" in index.options.negatives){
            data.append("case_sub_sub_type", index.options.negatives.case_sub_sub_type)
          }else{
            data.append("case_sub_sub_type", null)
          }

          let user_id = JSON.parse(localStorage.getItem('user_id'));
          data.append("action", "updatePause")
          data.append("task_id", this.returned_task.id)
          data.append("user_id", user_id)
          data.append("address", index.address)
          data.append('description','Checklist Failed'+index.label+'-'+index.answer);
          data.append('desc','Checklist Failed'+index.label+'-'+index.answer);
          data.append('longitude',this.longitude);
          data.append('latitude',this.latitude);
          axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/cases/', data, config)
              .then((res) => {
                Notify.create({
                  color: 'green-4',
                  textColor: 'white',
                  icon: 'cloud_done',
                  message: 'Case created!'
                })
                if(res.data.data.caseID !== undefined){
                  let case_id = res.data.data.caseID;
                  fetch(index.image)
                      .then((res) => res.blob())
                      .then((myBlob) => {
                        let file_config = {
                          header: {
                            'Content-Type': 'multipart/form-data'
                          }
                        }
                        let file_data = new FormData();
                        file_data.append("action", "uploadFileCase")
                        file_data.append("file_note","checklist_failed"+'_'+case_id)
                        file_data.append("case_id", case_id);
                        file_data.append("desired_file_name", "checklist_failed"+'_'+case_id)
                        file_data.append("user_id", user_id)
                        file_data.append('file',myBlob,'file');
                        axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                            .then(() => {
                              Notify.create({
                                color: 'green-4',
                                textColor: 'white',
                                icon: 'cloud_done',
                                message: 'File Uploaded'
                              })
                            })
                            .catch((error) => {
                              exception =1;
                              Notify.create({
                                color: 'red-5',
                                textColor: 'white',
                                icon: 'warning',
                                message: 'File FAILED: ' + error
                              })
                            }).finally(() => {
                          //Perform action in always
                        });
                      });
                }

              })
              .catch((error) => {
                exception = 1;
                Notify.create({
                  color: 'red-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'Checklist FAILED: ' + error
                })
              }).finally(() => {
            //Perform action in always
          });
        });

        if(exception ===0){
          this.hideAllModals();
          this.updateTaskStatus(12);
        }
      }

    },
    submitPauseReason(){
      let data = new FormData();
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      let user_id = JSON.parse(localStorage.getItem('user_id'));

      data.append("action", "updatePause")
      data.append("task_id", this.returned_task.id)
      data.append("user_id", user_id)
      data.append('pause_note',this.lastPauseReason);
      data.append('longitude',this.longitude);
      data.append('latitude',this.latitude);
      axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/pause-reasons', data, config)
          .then(() => {
            Notify.create({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Pause Reason saved!'
            })
            if(this.lastPauseReason==='Sleep'){
              this.pausePhotos.forEach((thing,index) => {
                if (thing !== undefined) {
                  fetch(thing)
                      .then((res) => res.blob())
                      .then((myBlob) => {
                        let file_config = {
                          header: {
                            'Content-Type': 'multipart/form-data'
                          }
                        }
                        let file_data = new FormData();
                        file_data.append("action", "uploadFileTask")
                        file_data.append("file_note",'pause_'+this.lastPauseReason+'_'+this.returned_task.id)
                        file_data.append("task_id", this.returned_task.id)
                        file_data.append("desired_file_name", 'pause_photo'+this.returned_task.id+'_'+index)
                        file_data.append("user_id", user_id)
                        file_data.append('file',myBlob,'file');
                        axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                            .then(() => {
                              Notify.create({
                                color: 'green-4',
                                textColor: 'white',
                                icon: 'cloud_done',
                                message: 'File Uploaded'
                              })
                            })
                            .catch((error) => {
                              Notify.create({
                                color: 'red-5',
                                textColor: 'white',
                                icon: 'warning',
                                message: 'File FAILED: ' + error
                              })
                            }).finally(() => {
                          //Perform action in always
                        });
                      });
                  thing = null;
                }
              });
              this.hideAllModals();
              this.updateTaskStatus(16);
              this.refreshData();
            }else{
              this.updateTaskStatus(12);
              this.hideAllModals();
              this.updateSpeeds();
              this.refreshData();
            }

          }).catch((error) => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'FAILED: ' + error
            })
          }).finally(() => {

      });
    },
    submitOdoMeter(type) {
      let user_id = JSON.parse(localStorage.getItem('user_id'));
      let data = new FormData();
      if(type==="end"){
        if(this.returned_task.odo_start > this.returned_task.odo_end){
          Notify.create({
            color: 'red-4',
            textColor: 'white',
            icon: 'warning',
            message: 'End Odometer reading cannot be less than start!'
          })
          return;
        }
      }
      data = {
        "action": "updateOdometer",
        "id": this.returned_task.id,
        "type" : type,
        "status": status,
        "user_id": user_id,
        "odo_start" :this.returned_task.odo_start,
        "odo_end" : this.returned_task.odo_end
      }
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      if(this.returned_task.image===undefined){
        Notify.create({
          color: 'red-4',
          textColor: 'white',
          icon: 'warning',
          message: 'Image required!'
        })
        return;
      }
      axios.put(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks/' + this.returned_task.id, data, config)
          .then(() => {
            Notify.create({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Odometer Sent!! '
            })
            fetch(this.returned_task.image)
                .then((res) => res.blob())
                .then((myBlob) => {
                  let file_config = {
                    header: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
                  let file_data = new FormData();
                  file_data.append("action", "uploadFileTask")
                  file_data.append("file_note","Start ODO: " +this.returned_task.odo_start)
                  file_data.append("task_id", this.returned_task.id)
                  file_data.append("desired_file_name", "ODO_"+type+"_"+this.returned_task.odo_start)
                  file_data.append("user_id", user_id)
                  file_data.append('file',myBlob,'file');
                  axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                      .then(() => {
                        Notify.create({
                          color: 'green-4',
                          textColor: 'white',
                          icon: 'cloud_done',
                          message: 'File Uploaded'
                        })
                        this.returned_task.image = null;
                      })
                      .catch((error) => {
                        Notify.create({
                          color: 'red-5',
                          textColor: 'white',
                          icon: 'warning',
                          message: 'File FAILED: ' + error
                        })
                      }).finally(() => {
                    //Perform action in always
                  });
                });
            if(this.app_status==="odometer"){
              this.app_status = "post-odometer";
              localStorage.setItem('app_status', JSON.stringify(this.app_status));
              this.hideAllModals();
              this.updateTaskStatus(12);
            }
            if(this.app_status==="onsite") {
              this.app_status = "post-odometer-onsite";
              localStorage.setItem('app_status', JSON.stringify(this.app_status));
              this.hideAllModals();
              this.updateTaskStatus(13);
            }
          })
          .catch((error) => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Odometer sending Odometer ' + error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
    updateLocalStorageStatus(status) {
      switch (status) {
        case "1":
          this.app_status = "new";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "2":
          this.app_status = "closed";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "3":
          this.app_status = "rejected";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "4":
          this.app_status = "accepted";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "5":
          this.app_status = "enroute";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.startDriving();
          break;
        case "6":
          this.app_status = "onsite";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if(this.displayEndOdoModal===false){
            this.displayEndOdoModal=true;
          }
          break;
        case "7":
          if (this.displayPauseModal === false) {
            this.displayPauseModal = true;
          }
          if (this.displayPauseModal === false) {
            this.displayPauseModal = true;
          }
          this.app_status = "paused";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "8":
          this.app_status = "pre-check-lists";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayPreChecklists === false) {
              this.getPreChecklists();
          }
          break;
        case "9":
          this.app_status = "pre-check-lists-complete";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "11":
          this.app_status = "odometer";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "12":
          this.app_status = "post-odometer";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "13":
          this.app_status = "post-odometer-onsite";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "14":
          this.app_status = "customer-signature";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if(this.displayCustomerSignatureModal ===false){
            this.displayCustomerSignatureModal = true;
          }
          break;
        case "15":
          this.hideAllModals();
          this.app_status = "sign-off";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if(this.displayDeliveryModal ===false){
            this.displayDeliveryModal = true;
          }
          break;
        case "16":
          this.hideAllModals();
          this.app_status = "pause-checklists";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if(this.displayPauseChecklists ===false){
            this.displayPauseChecklists = true;
          }
          break;
        case "17":
          this.hideAllModals();
          this.app_status = "awaiting-offload";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        default:
          this.forceRefresh();

      }
    },
    updateTaskStatus(status) {
      let statusBefore =this.app_status;
      this.updateLocalStorageStatus(status)
      let user_id = JSON.parse(localStorage.getItem('user_id'));
      let data = new FormData();
      data = {
        "action": "updateStatus",
        "id": this.returned_task.id,
        "status": status,
        'note': 'Changed from '+statusBefore+ ' to '+this.app_status,
        "user_id": user_id
      }
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.put(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks/' + this.returned_task.id, data, config)
          .then(() => {
            Notify.create({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Tasks Status Updated !! '
            })
            this.refreshData();
          })
          .catch((error) => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error accepting tasks: ' + error
            })
          }).finally(() => {
        //Perform action in always
      });
      axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/users/', {
        next_received: this.app_status + ' ' + this.returned_task.id
      })
          .then(() => {
            Notify.create({
              color: 'green-5',
              textColor: 'white',
              icon: 'success',
              message: 'Awaiting status updated !! '
            });
          })
          .catch(() => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Failed updating awaiting status !! '
            })
          }).finally(() => {
        //Perform action in always
      });
    },
    submitCustomerSignature(){
        this.updateTaskStatus('15');
    },
    completeDelivery(){
      this.customer_signature =  localStorage.getItem('sign_off_signature_base64') ;
      let exception = 1;
      if(this.returned_task.returns_y_n  === "Yes"){
        if(this.delivery_returns === undefined){
          Notify.create({
            color: 'red-4',
            textColor: 'white',
            icon: 'warning',
            message: 'No return photos taken!'
          })
          return;
        }
        if(this.returned_task.returns_y_n === undefined){
          Notify.create({
            color: 'red-4',
            textColor: 'white',
            icon: 'warning',
            message: 'Please fill return status'
          })
          return;
        }
        if(this.returned_task.possible_reason_for_returns === undefined){
          Notify.create({
            color: 'red-4',
            textColor: 'white',
            icon: 'warning',
            message: 'Please choose a reason for return!!'
          })
          return;
        }
        if(this.returned_task.reason_for_returns === undefined){
          Notify.create({
            color: 'red-4',
            textColor: 'white',
            icon: 'warning',
            message: 'Please fill a note for reason for return!!'
          })
          return;
        }
      }
      if(this.customer_satisfaction=== undefined){
        Notify.create({
          color: 'red-4',
          textColor: 'white',
          icon: 'warning',
          message: 'Satisfaction not captured'
        })
        return;
      }

      if(this.customer_signature=== undefined){
        Notify.create({
          color: 'red-4',
          textColor: 'white',
          icon: 'warning',
          message: 'No signature captured!'
        })
        return;
      }
      if(this.delivery_notes === undefined){
          Notify.create({
            color: 'red-4',
            textColor: 'white',
            icon: 'warning',
            message: 'No delivery note photos taken!'
          })
          return;
      }
      if(this.returned_task.delivery_code === undefined){
        Notify.create({
          color: 'red-4',
          textColor: 'white',
          icon: 'warning',
          message: 'Please fill delivery code nr!'
        })
        return;
      }

      // Some comments here
      exception = 0;
      let user_id = JSON.parse(localStorage.getItem('user_id'));
      if(exception === 0){
        this.delivery_notes.forEach((thing,index) => {
            if (thing !== undefined) {
              fetch(thing)
                  .then((res) => res.blob())
                  .then((myBlob) => {
                    let file_config = {
                      header: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                    let file_data = new FormData();
                    file_data.append("action", "uploadFileTask")
                    file_data.append("file_note","delivery_note_"+this.returned_task.id)
                    file_data.append("task_id", this.returned_task.id)
                    file_data.append("desired_file_name", 'delivery_note_photo_'+this.returned_task.delivery_code+'_'+index)
                    file_data.append("delivery_code", this.returned_task.delivery_code);
                    file_data.append("user_id", user_id)
                    file_data.append('file',myBlob,'file');
                    axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                        .then(() => {
                          Notify.create({
                            color: 'green-4',
                            textColor: 'white',
                            icon: 'cloud_done',
                            message: 'File Uploaded'
                          })
                        })
                        .catch((error) => {
                          Notify.create({
                            color: 'red-5',
                            textColor: 'white',
                            icon: 'warning',
                            message: 'File FAILED: ' + error
                          })
                        }).finally(() => {
                      //Perform action in always
                    });
                  });
              thing = null;
            }
          });
        this.delivery_returns.forEach((thing,index) => {
          if (thing !== undefined) {
            fetch(thing)
                .then((res) => res.blob())
                .then((myBlob) => {
                  let file_config = {
                    header: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }
                  let file_data = new FormData();
                  file_data.append("action", "uploadFileTask")
                  file_data.append("file_note","delivery_return_"+this.returned_task.id)
                  file_data.append("task_id", this.returned_task.id)
                  file_data.append("desired_file_name", 'return_photo_'+this.returned_task.delivery_code+'_'+index)
                  file_data.append("user_id", user_id)
                  file_data.append('file',myBlob,'file');
                  axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                      .then(() => {
                        Notify.create({
                          color: 'green-4',
                          textColor: 'white',
                          icon: 'cloud_done',
                          message: 'File Uploaded'
                        })
                      })
                      .catch((error) => {
                        Notify.create({
                          color: 'red-5',
                          textColor: 'white',
                          icon: 'warning',
                          message: 'File FAILED: ' + error
                        })
                      }).finally(() => {
                    //Perform action in always
                  });
                });
            thing = null;
          }
        });

        fetch(this.customer_signature)
            .then((res) => res.blob())
            .then((myBlob) => {
              let file_config = {
                header: {
                  'Content-Type': 'multipart/form-data'
                }
              }
              let file_data = new FormData();
              file_data.append("action", "uploadFileTask")
              file_data.append("file_note","delivery_note_"+this.returned_task.id)
              file_data.append("task_id", this.returned_task.id)
              file_data.append("desired_file_name", 'signoff_client')
              file_data.append("user_id", user_id)
              file_data.append('file',myBlob,'file');
              axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                  .then(() => {
                    Notify.create({
                      color: 'green-4',
                      textColor: 'white',
                      icon: 'cloud_done',
                      message: 'File Uploaded'
                    })
                  })
                  .catch((error) => {
                    Notify.create({
                      color: 'red-5',
                      textColor: 'white',
                      icon: 'warning',
                      message: 'File FAILED: ' + error
                    })
                  }).finally(() => {
                //Perform action in always
              });
            });
        if(this.returned_task.returns_y_n  === "Yes"){
          let config = {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          }
          let data = new FormData();
          let user_id = JSON.parse(localStorage.getItem('user_id'));
          data.append("action", "updatePause")
          data.append("task_id", this.returned_task.id)
          data.append("user_id", user_id)
          data.append("address", this.returned_task.address)
          data.append('desc','Item Returned on Delivery: '+this.returned_task.id+' Reason: '+this.returned_task.possible_reason_for_returns + ' Note: '+this.returned_task.reason_for_returns);
          data.append('description','Item Returned on Delivery: '+this.returned_task.id+' Reason: '+this.returned_task.possible_reason_for_returns + ' Note: '+this.returned_task.reason_for_returns);
          data.append('longitude',this.longitude);
          data.append('latitude',this.latitude);
          axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/cases/', data, config)
              .then(() => {
                Notify.create({
                  color: 'green-4',
                  textColor: 'white',
                  icon: 'cloud_done',
                  message: 'Case created!'
                })
              })
              .catch((error) => {
                Notify.create({
                  color: 'red-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'Checklist FAILED: ' + error
                })
              }).finally(() => {
            //Perform action in always
          });
        }
      }

      this.updateTaskDelivery();
      this.updateTaskStatus(2)

      Notify.create({
        color: 'green-4',
        textColor: 'white',
        icon: 'cloud_done',
        message: 'Tasks Completed !! '
      })

      this.displayAwaitingModal = true;
      // this.$router.push({ name: 'home'});
    },
    updateTaskDelivery() {
      let user_id = JSON.parse(localStorage.getItem('user_id'));
      let data = new FormData();
      data = {
        "action": "doDelivery",
        "id": this.returned_task.id,
        "user_id": user_id,
        "satisfaction": this.customer_satisfaction,
        "delivery_note_nr": this.returned_task.delivery_code,
        "return_reason": this.returned_task.possible_reason_for_returns,
        "return_note": this.returned_task.reason_for_returns,
        "return_status": this.returned_task.returns_y_n
      }
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      let exception = 1;
      if(parseInt(this.customer_satisfaction) > 0){
        exception = 0;
      }
      if(this.returned_task.delivery_code !== undefined){
        exception = 0
      }
      if(exception === 0){
        axios.put(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks/' + this.returned_task.id, data, config)
            .then(() => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Delivery Updated !! '
              })
              this.refreshData();
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Error accepting tasks: ' + error
              })
            }).finally(() => {
          //Perform action in always
        });
      }
    },
    checkMoving() {
      let sum = this.speed_5min + this.speed_4min + this.speed_3min + this.speed_2min + this.speed_1min / 5;
      if (this.app_status ==="enroute") {
        if (sum < 5) {
          if ((this.app_status !=="paused") && (this.app_status !=="awaiting-offload")) {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Pause Triggered!',
              timeout: 10000,
            })
            this.triggerPause();
          }
          this.app_status = "paused";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.updateTaskStatus('7');
        }
      }
    },
    triggerPause(){
      this.updateTaskStatus('7')
    },
    checkAppStatus() {
      switch (this.returned_task.status_id) {
        case "1":
          this.app_status = "new";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "2":
          this.app_status = "closed";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          this.displayAwaitingModal = true;
          break;
        case "3":
          this.app_status = "rejected";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "4":
          this.app_status = "accepted";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "5":
          this.app_status = "enroute";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "6":
          this.app_status = "onsite";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if(this.displayEndOdoModal===false){
            this.displayEndOdoModal=true;
          }
          break;
        case "7":
          this.app_status = "paused";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayPreChecklists===false) {
            this.displayPauseModal=true;
          }
          break;
        case "8":
          this.app_status = "pre-check-lists";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayPreChecklists===false) {
            this.getPreChecklists();
          }
          break;
        case "9":
          this.app_status = "pre-check-lists-complete";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "11":
          this.app_status = "odometer";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayStartOdoModal === false) {
            this.displayStartOdoModal =true;
          }
          break;
        case "12":
          this.app_status = "post-odometer";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "13":
          this.app_status = "post-odometer-onsite";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.displayEndOdoModal = false;
          break;
        case "14":
          this.app_status = "customer-signature";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.displayEndOdoModal = false;
          if (this.displayCustomerSignatureModal === false) {
            this.displayCustomerSignatureModal = true;
          }
          break;
        case "15":
          this.app_status = "sign-off";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.displayEndOdoModal = false;
          if (this.displayDeliveryModal === false) {
            this.displayDeliveryModal = true;
          }
          break;
        case "16":
          this.app_status = "pause-checklists";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          if (this.displayPauseChecklists === false) {
            this.displayPauseChecklists = true;
          }
          break;
        case "17":
          this.app_status = "awaiting-offload";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;

        default:
          this.forceRefresh();
      }
    },
    hideAllModals(){
      this.displayPauseModal = false;
      this.displayStartOdoModal = false;
      this.displayEndOdoModal = false;
      this.displayPreChecklists = false;
      this.displayDeliveryModal = false;
      this.displayCustomerSignatureModal = false;
      this.displayPauseChecklists = false;
    },
    createCase(){
      //TODO : Create case Logic
    },
    startDriving() {
      this.speed_interval_counter = 0;
      this.speed_5min = 5;
      this.speed_4min = 5;
      this.speed_3min = 5;
      this.speed_2min = 5;
      this.speed_1min = 5;
      this.updateTaskStatus(5);
    },
    postOdometer(){
      this.updateTaskStatus('11');
      if(this.displayStartOdoModal === false){
          this.displayStartOdoModal =true;
      }
    },
    // commented line
    forceRefresh(){
      this.case_status += 1;
      this.checkAppStatus();
      console.log("Force refresh called KEY:"+this.case_status);
      this.$forceUpdate();  // Notice we have to use a $ here
    },
    calculateDistance(){
      this.distance_away = this.haversine_distance()
    },
    b64toBlob(b64Data, contentType='', sliceSize=512){
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    toRad (input){
      return input * Math.PI / 180;
    },
    haversine_distance() {
      var lat2 = this.returned_task.gps_lat;
      var lon2 = this.returned_task.gps_lng;
      var lat1 = this.latitude;
      var lon1 = this.longitude;

      var R = 6371; // km
//has a problem with the .toRad() method below.
      var x1 = lat2-lat1;
      var dLat = this.toRad(x1);
      var x2 = lon2-lon1;
      var dLon = this.toRad(x2);
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return d;
    },
    any_haversine_distance(lat1,lat2,lon1,lon2) {
      var R = 6371; // km
//has a problem with the .toRad() method below.
      var x1 = lat2-lat1;
      var dLat = this.toRad(x1);
      var x2 = lon2-lon1;
      var dLon = this.toRad(x2);
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return d;
    },

    async takePhoto(input) {
      this.displayPreChecklists = false;
      this.displayStartOdoModal = false;
      this.displayEndOdoModal = false;
      this.displayDeliveryModal = false;
      this.displayPauseModal = false;

      const options = {
        resultType: CameraResultType.Uri
      };

      const originalPhoto = await Camera.getPhoto(options);
      if( (this.app_status==="sign-off") || (this.app_status==="paused") ){
          input.push(originalPhoto.webPath)
      }else{
        input.image = originalPhoto.webPath;
      }

      // Can be set to the src of an image now
      if (this.app_status == "pre-check-lists") {
        this.displayPreChecklists = true;
      }
      if (this.app_status == "odometer") {
        this.displayStartOdoModal = true;
      }
      if (this.app_status == "onsite") {
        this.displayEndOdoModal = true;
      }
      if(this.app_status=="sign-off"){
        this.displayDeliveryModal = true;
      }
      if(this.app_status=="paused"){
        this.displayPauseModal = true;
      }

    },
    async takeSinglePhoto(input) {
      this.displayPreChecklists = false;
      const options = {
        resultType: CameraResultType.Uri
      };
      const originalPhoto = await Camera.getPhoto(options);
      input.image = originalPhoto.webPath;
      this.displayPreChecklists = true;
    },
  }
}
</script>
