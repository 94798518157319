

import Vuex from "vuex";
import axios from "axios";

const store = new Vuex.Store({
    // Your store properties (state, mutations, actions, etc.)
    state: {
        unread_message_counter: 0, // Initialize the unread message counter
        user_id: 0,
        token: null
    },
    mutations: {
        UPDATE_UNREAD_MESSAGE_COUNTER(state, value) {
            state.unread_message_counter = value;
        },
        UPDATE_USER_ID(state, value) {
            state.user_id = value;
        },
        UPDATE_TOKEN(state, value) {
            state.token = value;
        }
    },
    getters: {
        counter: state => state.unread_message_counter,
        user_id: state => state.user_id
    },
    actions: {
        initializeStore({ state }) {
            // Check if the ID exists
            if (localStorage.getItem('store')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('store')))
                );
            }
        },
        updateUnreadMessageCounter({ state, commit }) {
            let user_id = state.user_id;
            if(user_id){
                if(state.token !== null){
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
                    axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/message-counter?user_id=' + user_id)
                        .then((res) => {
                            commit('UPDATE_UNREAD_MESSAGE_COUNTER', res.data.data[0].unread_count);
                        })
                        .catch(() => {

                        }).finally(() => {
                    });
                }
            }

        },
        updateUserID({ commit }, value) {
            commit('UPDATE_USER_ID', value);
        }
    }
});


store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('store', JSON.stringify(state));
});

export default store;