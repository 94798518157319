<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title>
          {{ this.title }}
        </q-toolbar-title>
        <q-item-section avatar>
          <q-btn  color="secondary" icon="local_gas_station" @click="requestFuelPO()" label="Fuel" stack>
            <q-spinner-gears v-if="this.loading" color="black"></q-spinner-gears>
          </q-btn>
        </q-item-section>
        <q-item-section avatar>
          <q-btn  color="primary" icon="integration_instructions" @click="requestInstructions()" label="OPS" stack>
            <q-spinner-gears v-if="this.loading" color="black"></q-spinner-gears>
          </q-btn>
        </q-item-section>
        <q-item-section avatar>
          <q-btn  color="red" icon="emergency" @click="displayPanic()" label="Panic" stack></q-btn>
          <q-spinner-gears v-if="this.loading" color="black"></q-spinner-gears>
        </q-item-section>
        <div>2.0</div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2"
    >
      <q-dialog v-model="this.displayPanicModal" transition-show="rotate" transition-hide="rotate">
        <q-card class="bg-teal text-white" style="width: 100%">
          <q-card-section class="q-pt-none">
            <q-form
                method="post" @submit="this.submitPanic()"
                class="q-gutter-md">
              <div>
                <q-card-section>
                  <div class="text-h6">Panic</div>
                </q-card-section>
                <q-card-section>
                  <q-option-group
                      :options="this.panicReasons"
                      type="radio"
                      v-model="this.panicReason"
                      @click="this.logStuff()"
                  ></q-option-group>
                </q-card-section>
                <q-btn v-if="( (this.panicReason==='15') || (this.panicReason==='3') )" @click="this.takePhoto(this.panicPhotos)" color="primary" round  icon="photo_camera"></q-btn>
                <div v-for="(panicPhoto,index) in this.panicPhotos" :key="index">
                  <q-img
                      :key="panicPhoto"
                      :src="panicPhoto"
                      style="height: 140px; max-width: 150px">
                  </q-img>
                </div>
              </div>
            </q-form>
          </q-card-section>

          <q-card-actions align="right" class="bg-white text-teal">
            <q-btn label="Done" @click="submitPanic()" type="submit" color="primary"></q-btn>
          </q-card-actions>

        </q-card>
      </q-dialog>

      <q-list>
        <q-item-label header>Menu</q-item-label>
        <q-item to="/" exact>
          <q-item-section avatar>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Home</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/open-tasks" exact>
          <q-item-section avatar>
            <q-icon name="settings" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Open Trips</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/open-checklists" exact>
          <q-item-section avatar>
            <q-icon name="checklist" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Open Checklists</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/accepted-tasks" exact>
          <q-item-section avatar>
            <q-icon name="settings" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Accepted Trips</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/closed-tasks" exact>
          <q-item-section avatar>
            <q-icon name="settings" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Closed Trips</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/inbox" exact>
          <q-item-section avatar>
            <q-icon name="message" />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              Inbox <span class="float-right">( {{ unread_message_counter }} )</span>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/compose-message" exact>
          <q-item-section avatar>
            <q-icon name="edit_note" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Compose new message</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/user-files" exact>
          <q-item-section avatar>
            <q-icon name="file_download" />
          </q-item-section>
          <q-item-section>
            <q-item-label>User Files</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/task-files" exact>
          <q-item-section avatar>
            <q-icon name="file_download" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Trip Files</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/case-files" exact>
          <q-item-section avatar>
            <q-icon name="file_download" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Case Files</q-item-label>
          </q-item-section>
        </q-item>
        <q-item to="/logout" exact>
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Log Out</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import {Notify} from "quasar";
import NoSleep from "nosleep.js";
import {useRouter} from "vue-router";
import {Geolocation} from "@capacitor/geolocation";
import {Camera, CameraResultType} from "@capacitor/camera";

export default {
  name: 'LayoutDefault',
  computed:{
    unread_message_counter() {
      return this.$store.getters.counter;
    },
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  data(){
      let branch_str = localStorage.getItem('branch');
      let branch_object = null;
      if(JSON.parse(branch_str)){
        // eslint-disable-next-line no-unused-vars
        branch_object = JSON.parse(branch_str);
      }else{
        // eslint-disable-next-line no-unused-vars
        branch_object = {
          id: 1,
          client: 'No branch'
        }
      }
      let branch = branch_object;
      return {
        branch,
        displayPanicModal : false,
        panic_department: null,
        panic_case_type: null,
        panicReasons : [],
        panicReason : null,
        longitude : null,
        case_id : null,
        latitude : null,
        loading : false,
        panicPhotos : [],
        userName : null,
      }
    },
    mounted(){
      this.getPanicReasons();
      this.storeLocation();
      this.getUsers();
    },
    setup () {
      const noSleep = new NoSleep();
      noSleep.enable();
      const router = useRouter();
      let token = JSON.parse( localStorage.getItem('token') );
      // eslint-disable-next-line no-unused-vars
      let user_id = JSON.parse( localStorage.getItem('user_id') );
      if( !token ){
        router.push({ name: "login"})
      }else {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      let title = process.env.VUE_APP_NAME;
      return {
        leftDrawerOpen: ref(false),
        title
      }
    },
    methods :{
    requestInstructions(){
      axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/users/', {
        next_received: 'Awaiting Ops instructions'
      })
          .then(() => {
            Notify.create({
              color: 'green-5',
              textColor: 'white',
              icon: 'success',
              message: 'Awaiting status updated !! '
            });
          })
          .catch(() => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Failed updating awaiting status !! '
            })
          }).finally(() => {
        //Perform action in always
      });
    },
    logStuff(){
      console.log(this.panicReason)
    },
    getUsers(){
        axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/users')
            .then((res) => {
              let user_id = JSON.parse( localStorage.getItem('user_id') );
              this.users = res.data.data;
              this.users.forEach((user) => {
                if(user.id===user_id){
                    this.userName = user.name;
                    localStorage.setItem( 'user_name', JSON.stringify(this.userName) );
                }
              });
            })
            .catch(() => {

            }).finally(() => {

        });
      },
    requestFuelPO(){
    this.loading = true;
    let config = {
      header: {
        'Content-Type': 'multipart/form-data'
      }
    }
    let data = new FormData();
    let user_id = JSON.parse(localStorage.getItem('user_id'));
    let user_name = JSON.parse( localStorage.getItem('user_name') );
    data.append("action", "RequestFuelPO")
    data.append("task_id", 0)
    data.append("user_id", user_id)
    data.append('case_type',"9")
    data.append('case_sub_type',"0")
    data.append("address", 'No Address')
    data.append('description','Driver '+user_name+' needs Fuel PO');
    data.append('desc','Driver '+user_name+' needs Fuel PO');
    data.append('longitude',this.longitude);
    data.append('latitude',this.latitude);
    axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/cases/', data, config)
        .then(() => {
          Notify.create({
            color: 'green-4',
            textColor: 'white',
            icon: 'cloud_done',
            message: 'Case created!'
          })
          this.loading = false;
        })
        .catch((error) => {
          Notify.create({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: 'Fuel PO Request FAILED: ' + error
          })
        }).finally(() => {
      //Perform action in always
    });
  },
      submitPanic(){
        this.displayPanicModal = false;
        this.loading = true;
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        let data = new FormData();

        let user_id = this.user_id;
        let user_name = JSON.parse(localStorage.getItem('user_name'));
        let panicReason = null;
        this.panicReasons.forEach((value) => {
          if(this.panicReason===value.value){
            panicReason = value.label;
          }
        });
        data.append("action", "doPanic")
        data.append("user_id", user_id)
        data.append("department" ,this.panic_department);
        data.append("id_department" ,this.panic_department);
        data.append("case_type" ,this.panic_case_type);
        data.append("id_type" ,this.panic_case_type);
        data.append("id_sub_type",this.panicReason);
        data.append("case_sub_type" ,this.panicReason);
        data.append('desc','Panic Received from '+user_name+' '+panicReason);
        data.append('description','Panic Received from '+user_name+' '+panicReason);
        data.append('longitude',this.longitude);
        data.append('latitude',this.latitude);
        axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/cases/', data, config)
            .then((res) => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Case created!'
              })
              this.displayPanicModal = false;
              console.log(res);
              if(res.data.data.caseID !== undefined){
                this.case_id = res.data.data.caseID;
              }
              this.panicPhotos.forEach((panicPhoto,index) => {
                fetch(panicPhoto)
                    .then((res) => res.blob())
                    .then((myBlob) => {
                      let file_config = {
                        header: {
                          'Content-Type': 'multipart/form-data'
                        }
                      }
                      let file_data = new FormData();
                      file_data.append("action", "uploadFileCase")
                      file_data.append("file_note","panic_"+index+'_'+this.case_id)
                      file_data.append("case_id", this.case_id);
                      file_data.append("desired_file_name", "panic_"+index+'_'+this.case_id)
                      file_data.append("user_id", user_id)
                      file_data.append('file',myBlob,'file');
                      console.log("Blob");
                      axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                          .then((res) => {
                            Notify.create({
                              color: 'green-4',
                              textColor: 'white',
                              icon: 'cloud_done',
                              message: 'File Uploaded'
                            })
                            console.log(res);
                          })
                          .catch((error) => {
                            Notify.create({
                              color: 'red-5',
                              textColor: 'white',
                              icon: 'warning',
                              message: 'File FAILED: ' + error
                            })
                          }).finally(() => {
                        //Perform action in always
                      });
                    });
              });
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Panic FAILED: ' + error
              })
            }).finally(() => {
          //Perform action in always
        });
        this.loading = false;
      },
    displayPanic(){
      if(this.displayPanicModal === false){
        this.displayPanicModal = true;
      }
    },
      getPanicReasons(){
        console.log("Getting panics");
        axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/case-sub-types/40?case_type_search=panic&branch_id='+this.branch.id)
            .then((res) => {
              let sub_types_array = [];
              res.data.data.forEach((reason) => {
                let sub_typesObject = {
                  "label": reason.name,
                  "value" : reason.id,
                }
                sub_types_array.push(sub_typesObject);
              });
              console.log("Panic Reasons");
              this.panic_department = res.data.department;
              this.panic_case_type = res.data.panic_case_type;
              this.panicReasons = sub_types_array;
              console.log(this.panicReasons);
            })
            .catch((error) => {
              console.log(error);
            }).finally(() => {
        });
      },

      storeLocation(){
      if (Geolocation.checkPermissions()) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true
        }).then(newPosition => {
          localStorage.setItem('latitude', JSON.stringify(newPosition.coords.latitude));
          this.latitude = newPosition.coords.latitude;
          localStorage.setItem('longitude', JSON.stringify(newPosition.coords.longitude));
          this.longitude = newPosition.coords.longitude;
        })
      } else {
        Geolocation.requestPermissions();
      }
    },
    async takePhoto() {
      this.displayPanicModal = false;
      const options = {
        resultType: CameraResultType.Uri
      };
      const originalPhoto = await Camera.getPhoto(options);
      this.panicPhotos.push(originalPhoto.webPath);
      this.displayPanicModal = true;
  },
  }
}
</script>

