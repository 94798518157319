<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <div id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 350px">
        <div :key="render_table">
            <div class="text-h3">Personal Files</div>
          <div class="q-col-gutter-md row items-start">
            <div v-for="(file,index) in returned_files" :key="index" class="col-6">
              <q-img
                  :src="file.url"
                  loading="lazy">
                <div class="absolute-bottom text-subtitle1 text-center">
                  {{file.file_name}}
                </div>
              </q-img>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import { useRouter } from 'vue-router'
import {useQuasar} from "quasar";

export default {
  name: 'UserFiles',
  setup(){
    const router = useRouter()
    let token = JSON.parse( localStorage.getItem('token') );
    if( token ){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }else{
      router.push({ name: "login"})
    }
    return {
    }
  },
  data(){
    return {
      returned_files : Object,
      render_table : null
    }
  },
  mounted(){
     this.syncData();
  },
  methods : {
    async syncData(){
      const $q = useQuasar();
      const user_id = JSON.parse( localStorage.getItem('user_id') );
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/user-files/'+user_id)
          .then((res) => {
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Files Retrieved !! '
            })
            console.log(res.data.data);
            this.returned_files = res.data.data;
          })
          .catch((error) => {
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving files: '+error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
  }
}
</script>
