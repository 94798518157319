<template>
  <q-page class="flex flex-center">
    <div style="min-height: 100vh;">
      <div class="q-pa-md" style="max-width: 400px">
        <q-form
            method="post" @submit.prevent="onSubmit"
            @reset="onReset"
            class="q-gutter-md"
        >
          <q-input
              filled
              v-model="email"
              name="email"
              label="Login User"
              hint="Personnel Number"
              lazy-rules
              :rules="[ val => val && val.length > 0 || 'Please type something']"
          ></q-input>

          <q-input
              filled
              type="password"
              name="password"
              v-model="password"
              label="Password"
              lazy-rules
              :rules="[ val => val && val.length > 0 || 'Please type something']"
          ></q-input>

          <q-toggle v-model="accept" label="I accept the license and terms"></q-toggle>

          <div>
            <q-btn label="Submit" type="submit" color="primary"></q-btn>
            <q-btn label="Reset" type="reset" color="primary" flat class="q-ml-sm"></q-btn>
          </div>
        </q-form>

      </div>
    </div>
  </q-page>
</template>

<style>
</style>

<script>
import { useQuasar } from 'quasar';
import { ref } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router' // <- import useRoute here
import {useStore} from "vuex";
export default {
  name: 'PageLogin',
  setup () {
    const $q = useQuasar()
    const router = useRouter();
    const store = useStore();

    const token = ref(null)
    const email = ref(null)
    const password = ref(null)
    const accept = ref(false)

    return {
      token,
      email,
      password,
      accept,
      store,

      onSubmit (evt) {
        console.log('@submit - do something here', evt)
        if (accept.value !== true) {
          $q.notify({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: 'You need to accept the license and terms first'
          })
        }
        else {
          let submitData = {
              "client_reference_number" : email.value,
              "password" : password.value
          };
          axios.post(process.env.VUE_APP_API_BASE_URL+'/api/authorize', submitData)
              .then((res) => {
                token.value = res.data.token
                  $q.notify({
                    color: 'green-4',
                    textColor: 'white',
                    icon: 'cloud_done',
                    message: 'Logged in successfully !! '+token.value
                  })
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.value;
                localStorage.setItem( 'token', JSON.stringify(token.value) );
                localStorage.setItem( 'user_id', JSON.stringify(res.data.user_id) );
                store.commit('UPDATE_USER_ID',res.data.user_id);
                store.commit('UPDATE_TOKEN',token.value);
                localStorage.setItem( 'app_status', JSON.stringify('logged_in') );
                router.push({ name: "home"})
              })
              .catch((error) => {
                $q.notify({
                  color: 'red-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'Log in Failed'+error
                })
              }).finally(() => {
            //Perform action in always
          });

        }
      },

      onReset () {
        email.value = null
        password.value = null
        accept.value = false
      }
    }
  }
}
</script>
