<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->



  <div :key="this.page_status" id="q-app" style="min-height: 100vh;">
    <q-dialog v-model="this.displayChecklists" persistent transition-show="rotate" transition-hide="rotate">
      <q-card class="bg-teal text-white" style="width: 100%">
        <q-card-section class="q-pt-none">
          <q-form
              method="post" @submit="this.submitChecklists()"
              class="q-gutter-md">
            <div v-for="(checkItem,index) in this.preChecklists" :key="index">
              <q-card-section>
                <div class="text-h6">Checklist {{this.forms[index][0].name}}</div>
              </q-card-section>
              <div v-if="this.returned_task.task_kategory_id ==='20'">
                <q-card-section>
                  <label>Choose the Officers to be clocked in</label>
                  <q-select
                      filled
                      v-model="this.multiple"
                      multiple
                      emit-value
                      :options="this.options"
                      label="Choose Employees"
                  ></q-select>
                </q-card-section></div>
              <q-card-section v-for="(formField,form_index) in checkItem" :key="form_index">
                <label>{{formField['desc']}}</label>
                <div v-if="formField.options.multi==='1'">
                  <q-select
                      filled
                      v-model="formField.answer"
                      multiple
                      emit-value
                      :options="formField.options.options"
                      :label="formField['desc']"
                  ></q-select>
                </div>
                <div v-if="( formField.options.multi==='0' ) && (formField['type']==='choices')">
                  <q-select style="margin-bottom: 10px" v-model="formField.answer" :options="formField.options.options">
                  </q-select>
                </div>
                <div v-if="(formField['type']==='boolean')">
                  <q-select style="margin-bottom: 10px" v-model="formField.answer" :options="[formField.options.false,formField.options.true]">
                  </q-select>
                  <div v-if="formField.answer===formField.options.false">
                    <q-btn v-if="formField.answer===formField.options.false" @click="this.takeSinglePhoto(formField)" color="primary" round  icon="photo_camera"></q-btn>
                    <q-item-label style="margin-top: 20px">Enter fault description</q-item-label>
                    <q-input v-model="formField.answer_reason" type="text"></q-input>
                    <q-img
                        :key="formField.image" v-if="formField.image"
                        :src="formField.image"
                        style="margin-top: 20px; height: 140px; max-width: 150px"
                    >
                      <template v-slot:loading>
                        <q-spinner-gears color="white"></q-spinner-gears>
                      </template>
                    </q-img>
                  </div>
                </div>
                <div v-if="(formField['type']==='text')">
                  <q-input type="text" style="margin-bottom: 10px" v-model="formField.answer">
                  </q-input>
                </div>
                <div v-if="(formField['type']==='datetime')">
                  <q-input type="date" mask="YYYY-MM-DD" style="margin-bottom: 10px" v-model="formField.date">
                  </q-input>
                  <q-input type="time" style="margin-bottom: 10px" v-model="formField.time">
                  </q-input>
                </div>
                <div v-if="(formField['type']==='number')">
                  <q-input type="number" :rules="[ val => val >= 0 || 'Please use a non negative number' ]" style="margin-bottom: 10px" v-model="formField.answer">
                  </q-input>
                </div>
                <div v-if="(formField['type']==='signature')">
                  <SignatureCard :item_id="formField['id']"></SignatureCard>
                </div>
                <div v-if="(formField.options.photo_required===1)">
                  <q-item-label>A Photo is required for this Item</q-item-label>
                  <q-btn style="margin-top: 10px" @click="this.takeSinglePhoto(formField)" color="primary" round  icon="photo_camera"></q-btn>
                  <q-img
                      :key="formField.image" v-if="formField.image"
                      :src="formField.image"
                      style="height: 140px; max-width: 150px"
                  >
                    <template v-slot:loading>
                      <q-spinner-gears color="white"></q-spinner-gears>
                    </template>
                  </q-img>
                </div>
              </q-card-section>
            </div>
          </q-form>
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-teal">
          <q-btn label="Done" @click="this.submitChecklists()" type="submit" color="primary"></q-btn>
        </q-card-actions>

      </q-card>
    </q-dialog>
    <div class="q-pa-md" style="max-width: 100%">
      <div>
        <h5>{{this.app_status}}</h5>
        <q-card class="my-card bg-gray text-gray">
          <q-card-section>
            <q-item v-ripple>
              <q-item-section class="text-h5">{{this.returned_task.id}} - {{this.returned_task.title}} </q-item-section>
              <q-item-section avatar>
                <q-icon color="primary" name="settings"></q-icon>
              </q-item-section>
              <q-item-section avatar>
              </q-item-section>
            </q-item>
          </q-card-section>

        </q-card>
        <q-card class="bg-white text-white" style="width: 100%">
          <q-separator dark></q-separator>
          <q-list  bordered>
            <q-item>
              <q-item-section v-if='this.app_status === "new"'>
                <div class="q-pa-md q-gutter-sm">
                  <q-btn @click="this.updateTaskStatus('4')" color="secondary" icon="check" label="Accept Task"/>
                </div>
              </q-item-section>
              <q-item-section  v-if='this.app_status === "accepted"'>
                <q-card class="my-card bg-gray text-gray">
                  <q-btn @click="this.startChecklists()" color="secondary" icon="check" label="Perform Checklist"/>
                </q-card>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import {useQuasar} from "quasar";
import { Notify } from 'quasar';
import { Camera, CameraResultType } from "@capacitor/camera";
import { Geolocation } from '@capacitor/geolocation'
import SignatureCard from "@/components/SignatureCard.vue";

export default {
  name: 'ExecuteTasksPage',
  components: {
    SignatureCard
  },
  setup(){
    const router = useRouter()
    let token = JSON.parse( localStorage.getItem('token') );
    if( token ){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }else{
      router.push({ name: "login"})
    }
    return {

    }
  },
  data(){
    return {
      returned_task : Object,
      page_status : null,
      parameter_id : null,
      app_status : null,
      multiple : [],
      options : [],
      clockUsers : [],
      displayChecklists : false,
      preChecklists : Object,
      deliveryNote : Object,
      longitude : null,
      latitude : null
    }
  },
  mounted(){
    this.setupParameters();
    this.getData();
    this.getPreChecklists();
    this.checkAppStatus();
    this.getUsers();
    this.getLocation();
  },
  methods : {
    getPreChecklists() {
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/form-fields/' + this.parameter_id + '?type=pre')
          .then((res) => {
            this.preChecklists = res.data.data;
            console.log(this.preChecklists);
          })
          .catch(() => {
          }).finally(() => {
        //Perform action in always
      });
    },
    getUsers(){
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/users')
          .then((res) => {
            this.users = res.data.data;
            this.users.forEach((user) => {
              if(user.name!==null){
                this.options.push(user.name);
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    getLocation(){
      if (Geolocation.checkPermissions()) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true
        }).then(newPosition => {
          localStorage.setItem('latitude', JSON.stringify(newPosition.coords.latitude));
          this.latitude = newPosition.coords.latitude;
          localStorage.setItem('longitude', JSON.stringify(newPosition.coords.longitude));
          this.longitude = newPosition.coords.longitude;
        })
      }
    },
    submitChecklists(){
      if(this.returned_task.task_kategory_id == '20'){
        this.submitClockIn();
        this.submitStandardChecklists();
      }else{
        this.submitStandardChecklists();
      }
    },
    submitStandardChecklists() {
      let user_id = JSON.parse(localStorage.getItem('user_id'));
      let cases = [];
      let data = new FormData();
      let exception = 0;
      data.append("action", "updateStatus")
      data.append("task_id", this.returned_task.id)
      data.append("user_id", user_id)
      Object.keys(this.preChecklists).forEach(key => {
        this.preChecklists[key].forEach(thing => {
          if(thing.type==='datetime'){
            if(thing.time === undefined){
              exception = 1;
            }
            if(thing.date === undefined){
              exception =1;
            }
            if(exception === 1){
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Please fill in all fields! ' + thing.desc + ' is not filled'
              })
            }else{
              thing.answer = thing.date + ' ' + thing.time;
            }
          }
          if ( (thing.answer === undefined) && (thing.type !== 'signature')) {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Please fill in all fields! ' + thing.desc + ' is not filled'
            })
            exception = 1;
          }
          if (thing.options.photo_required == '1' && thing.image === undefined) {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Please upload photo in  required fields! ' + thing.desc + ' has no photo!'
            })
            exception = 1;
          }
          if (thing.type === 'signature'){
            let signature_base64 =  localStorage.getItem(thing.id+'_signature_base64') ;
            if(signature_base64=== undefined){
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Please sign the form'
              })
              exception = 1;
            }else{
              thing.answer = 'Signature '+thing.desc;
              thing.image = signature_base64;
            }
          }
          if ((thing.answer===thing.options.false) && (thing.options.negatives?.negative_case=='Yes') ) {
            cases.push(thing);
          }
        });
      });
      if(exception === 0){
        Object.keys(this.preChecklists).forEach(key => {
          this.preChecklists[key].forEach(thing => {
            if(thing.image !== undefined){
              fetch(thing.image)
                  .then((res) => res.blob())
                  .then((myBlob) => {
                    let file_config = {
                      header: {
                        'Content-Type': 'multipart/form-data'
                      }
                    }
                    let file_data = new FormData();
                    file_data.append("action", "uploadFileTask")
                    file_data.append("file_note", thing.desc)
                    file_data.append("task_id", this.returned_task.id)
                    file_data.append("desired_file_name", thing.id)
                    file_data.append("user_id", user_id)
                    file_data.append('file', myBlob, 'file');
                    axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                        .then(() => {
                          Notify.create({
                            color: 'green-4',
                            textColor: 'white',
                            icon: 'cloud_done',
                            message: 'File Uploaded'
                          })
                        })
                        .catch((error) => {
                          Notify.create({
                            color: 'red-5',
                            textColor: 'white',
                            icon: 'warning',
                            message: 'File FAILED: ' + error
                          })
                          exception = 1;
                        }).finally(() => {
                      //Perform action in always
                    });
                  });
            }
            data.append('field_' + thing.id, JSON.stringify(thing));
          });
        });
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/form-fields/', data, config)
            .then(() => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Checklist saved!'
              })
              this.app_status = "Post-Check-In";
              localStorage.setItem('app_status', JSON.stringify(this.app_status));
              this.displayChecklists = false;
              this.updateTaskStatus("2");
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Checklist FAILED: ' + error
              })
              exception = 1;
            }).finally(() => {
          //Perform action in always
        });

        cases.forEach(index => {
          let config = {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          }
          let data = new FormData();
          console.log("Submitting case");
          console.log(index);
          if("company" in index.options.negatives){
            data.append("company", index.options.negatives.company)
          }else{
            data.append("company", null)
          }
          if("department" in index.options.negatives){
            data.append("department", index.options.negatives.department)
          }else{
            data.append("department", null)
          }
          if("case_type" in index.options.negatives){
            data.append("case_type", index.options.negatives.case_type)
          }else{
            data.append("case_type", null)
          }
          if("case_sub_type" in index.options.negatives){
            data.append("case_sub_type", index.options.negatives.case_sub_type)
          }else{
            data.append("case_sub_type", null)
          }
          if("case_sub_sub_type" in index.options.negatives){
            data.append("case_sub_sub_type", index.options.negatives.case_sub_sub_type)
          }else{
            data.append("case_sub_sub_type", null)
          }

          let user_id = JSON.parse(localStorage.getItem('user_id'));
          data.append("action", "updatePause")
          data.append("task_id", this.returned_task.id)
          data.append("user_id", user_id)
          data.append("address", index.address)
          data.append('description',index.label+'-'+index.answer);
          data.append('desc',index.label+'-'+index.answer);
          data.append('longitude',this.longitude);
          data.append('latitude',this.latitude);
          axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/cases/', data, config)
              .then((res) => {
                Notify.create({
                  color: 'green-4',
                  textColor: 'white',
                  icon: 'cloud_done',
                  message: 'Case created!'
                })
                if(res.data.data.caseID !== undefined){
                  let case_id = res.data.data.caseID;
                  fetch(index.image)
                      .then((res) => res.blob())
                      .then((myBlob) => {
                        console.log(myBlob);
                        let file_config = {
                          header: {
                            'Content-Type': 'multipart/form-data'
                          }
                        }
                        let file_data = new FormData();
                        file_data.append("action", "uploadFileCase")
                        file_data.append("file_note","checklist_failed"+'_'+case_id)
                        file_data.append("case_id", case_id);
                        file_data.append("desired_file_name", "checklist_failed"+'_'+case_id)
                        file_data.append("user_id", user_id)
                        file_data.append('file',myBlob,'file');
                        axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/files/', file_data, file_config)
                            .then(() => {
                              Notify.create({
                                color: 'green-4',
                                textColor: 'white',
                                icon: 'cloud_done',
                                message: 'File Uploaded'
                              })
                            })
                            .catch((error) => {
                              Notify.create({
                                color: 'red-5',
                                textColor: 'white',
                                icon: 'warning',
                                message: 'File FAILED: ' + error
                              })
                            }).finally(() => {
                          //Perform action in always
                        });
                      });
                }
              })
              .catch((error) => {
                exception = 1;
                Notify.create({
                  color: 'red-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'Checklist FAILED: ' + error
                })
              }).finally(() => {
            //Perform action in always
          });
        });
        if(exception ===0){
          this.hideAllModals();
          this.updateTaskStatus(2);
          this.$router.push({ name: 'home'});
        }
      }
    },

    submitClockIn(){
      this.clockUsers = [];
      this.multiple.forEach( (selectedUser) => {
        this.users.forEach((user) => {
          console.log(user.name + ' vs ' + selectedUser)
          if (user.name === selectedUser) {
            this.clockUsers.push(user.id);
          }
        });
      });
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      let time = today.getTime();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = yyyy + '-' + mm + '-' + dd;
      let dates = [];
      dates[0] = formattedToday;
      dates[1] = time;

      let data = new FormData();
      data.append("comment", this.returned_task.title)
      data.append("task", this.returned_task.id)
      data.append("date",dates)
      data.append("uid",this.clockUsers);
      data.append('api_key',"iopsdfjoduf90sdf")
      data.append('gpslat', this.latitude)
      data.append('gpslng', this.longitude)
      let file_config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post('https://timesheets.hypercheck.duza.co.za/api/clock/add', data, file_config)
          .then(() => {
            Notify.create({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Clocked In'
            })
          })
          .catch((error) => {
            Notify.create({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Clock in FAILED: ' + error
            })
          }).finally(() => {
        //Perform action in always
        this.$router.push({ name: 'home'});
      });

    },
    setupParameters() {
      const route = useRoute()
      this.parameter_id = route.params.id;
    },

    startChecklists(){
      this.updateTaskStatus("8")
    },
    hideAllModals(){
      this.displayChecklists = false;
    },
    checkAppStatus() {
      switch (this.returned_task.status_id) {
        case "1":
          this.app_status = "new";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "2":
          this.app_status = "closed";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "3":
          this.app_status = "rejected";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "4":
          this.app_status = "accepted";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "5":
          this.app_status = "enroute";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "6":
          this.app_status = "Start-Staff-Clock-In";
          if (this.displayStaffClockInModal===false) {
            console.log("Showing staff clock in Modal");
            this.displayStaffClockInModal = true;
          }
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "7":
          this.app_status = "Start-Inventory-Check-In";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayInventoryCheckInModal===false) {
            this.displayInventoryCheckInModal = true;
          }
          break;
        case "8":
          this.app_status = "Start-Check-Lists";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayChecklists===false) {
            this.displayChecklists = true;
            console.log("Displaying through check status Checklists")
          }
          break;
        case "9":
          this.app_status = "Post-Check-In";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "11":
          this.app_status = "Check-Price";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayPriceCheckModal===false) {
            this.displayPriceCheckModal = true;
          }
          break;
        case "12":
          this.app_status = "post-odometer";
          this.hideAllModals();
          break;
        default:
          this.forceRefresh();
      }
    },
    updateLocalStorageStatus(status) {
      console.log("Updating Status to "+status);
      switch (status) {
        case "1":
          this.app_status = "new";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          this.hideAllModals();
          break;
        case "2":
          this.app_status = "closed";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "3":
          this.app_status = "rejected";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "4":
          this.app_status = "accepted";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "5":
          this.app_status = "enroute";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "6":
          this.app_status = "Start-Staff-Clock-In";
          if (this.displayStaffClockInModal===false) {
            console.log("Showing staff clock in Modal");
            this.displayStaffClockInModal = true;
          }
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "7":
          console.log("Case of 7 hit");
          console.log(this.displayInventoryCheckInModal);
          this.app_status = "Start-Inventory-Check-In";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayInventoryCheckInModal===false) {
            console.log("Showing inventory Modal");
            this.displayInventoryCheckInModal = true;
          }
          break;
        case "8":
          this.app_status = "Start-Check-Lists";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayChecklists === false) {
            this.displayChecklists = true;
          }
          break;
        case "9":
          this.hideAllModals();
          this.app_status = "Post-Check-In";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "11":
          this.app_status = "Check-Price";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if (this.displayPriceCheckModal===false) {
            console.log("Showing Price Check Modal");
            this.displayPriceCheckModal = true;
          }
          break;
        case "12":
          this.app_status = "post-odometer";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "13":
          this.app_status = "post-odometer-onsite";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          break;
        case "14":
          this.app_status = "customer-signature";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if(this.displayCustomerSignatureModal ===false){
            this.displayCustomerSignatureModal = true;
          }
          break;
        case "15":
          this.hideAllModals();
          this.app_status = "sign-off";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if(this.displayDeliveryModal ===false){
            this.displayDeliveryModal = true;
          }
          break;
        case "16":
          this.hideAllModals();
          this.app_status = "pause-checklists";
          localStorage.setItem('app_status', JSON.stringify(this.app_status));
          if(this.displayPauseChecklists ===false){
            this.displayPauseChecklists = true;
          }
          break;
        default:
          this.forceRefresh();

      }
    },
    updateTaskStatus(status) {
      let statusBefore =this.app_status;
      this.updateLocalStorageStatus(status)
      if(statusBefore !== this.app_status){
        let user_id = JSON.parse(localStorage.getItem('user_id'));
        let data = new FormData();
        data = {
          "action": "updateStatus",
          "id": this.returned_task.id,
          "status": status,
          'note': 'Changed from '+statusBefore+ ' to '+this.app_status,
          "user_id": user_id
        }
        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axios.put(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks/' + this.returned_task.id, data, config)
            .then(() => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Tasks Status Updated !! '
              })
            })
            .catch((error) => {
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Error accepting tasks: ' + error
              })
            }).finally(() => {
          //Perform action in always
        });
      }
    },

    async getData() {
      const $q = useQuasar();
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks/' + this.parameter_id)
          .then((res) => {
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Tasks Retrieved !! '
            })
            this.returned_task = res.data.data[0];
            console.log(this.returned_task);
            this.updateLocalStorageStatus(this.returned_task.status_id);
          })
          .catch((error) => {
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: ' + error
            })
          }).finally(() => {
        this.forceRefresh();
        //Perform action in always
      });
    },

    forceRefresh(){
      this.page_status += 1;
      console.log("Force refresh called KEY:"+this.page_status);
      this.$forceUpdate();  // Notice we have to use a $ here
    },

    async takeArrayPhoto(input) {
      this.hideAllModals();

      const options = {
        resultType: CameraResultType.Uri
      };

      const originalPhoto = await Camera.getPhoto(options);
      input.push(originalPhoto.webPath);


    },

    async takeSinglePhoto(input) {
      console.log(this.displayChecklists);
      this.displayChecklists = false;

      const options = {
        resultType: CameraResultType.Uri
      };
      const originalPhoto = await Camera.getPhoto(options);
      input.image = originalPhoto.webPath;

      console.log(this.displayChecklists);
      this.displayChecklists = true;

    },

  }
}
</script>
