import { initializeApp, getApps } from 'firebase/app';

const config = {
    apiKey: process.env.VUE_APP_apiKey,
    authDomain: process.env.VUE_APP_authDomain,
    projectId: process.env.VUE_APP_projectId,
    storageBucket: process.env.VUE_APP_storageBucket,
    messagingSenderId: process.env.VUE_APP_messagingSenderId,
    appId: process.env.VUE_APP_appId,
};

let firebaseApp;

if (!getApps().length) {
    firebaseApp = initializeApp(config);
}

export default firebaseApp;
