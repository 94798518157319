<template>
  <router-view :key="this.forceReload"/>
</template>
<script>
import firebaseApp from "@/firebaseConfig";
const messaging = getMessaging(firebaseApp);
import { getMessaging,getToken,onMessage } from "firebase/messaging";
import axios from "axios";
import {Geolocation} from "@capacitor/geolocation";
import NoSleep from "nosleep.js";
import {useRouter} from "vue-router";
import {Notify} from "quasar";

export default {
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
    const router = useRouter();
    let token = JSON.parse( localStorage.getItem('token') );
    // eslint-disable-next-line no-unused-vars
    let user_id = JSON.parse( localStorage.getItem('user_id') );
    if( !token ){
      router.push({ name: "login"})
    }else {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  },
  mounted(){
    console.log()
    this.enableNotifications();
    if (Geolocation.checkPermissions()){
       console.log("Geolocation granted");
    }
  },
  methods: {
    registerToken(token) {
      axios.post(
          process.env.VUE_APP_API_BASE_URL+"/api/firebase",
          {
            token: token
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json"
            }
          }
      ).then(() => {
      })
    },
    initializeFirebase() {
      getToken(messaging, {vapidKey: process.env.VUE_APP_vapid_Key})
          .then((token) => {
            console.log("Token is " + token);
            this.registerToken(token)
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      onMessage(messaging, () => {
        this.playSound();
        Notify.create({
          message: "New message received",
          color: "primary",
          position: "top",
          timeout: 2000
        });
      });
    },
    enableNotifications() {
      if (!("Notification" in window)) {
        alert("Notifications are not supported");
      } else if (Notification.permission === "granted") {
            this.initializeFirebase();
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            this.initializeFirebase();
          }
        })
      } else {
        alert("No permission to send notification")
      }
      this.requestPermission = Notification.permission;
    },



    playSound () {
      var data = { soundurl : 'https://api.blackwidow.org.za/sos.mp3'}
      var audio = new Audio(data.soundurl);
      audio.play();
    },
    speakMessage() {
      if (this.synth.speaking) {
        console.error('speechSynthesis.speaking');
        return;
      }
      if (this.userInput !== '') {
        this.validation = false;
        let sInstance = new SpeechSynthesisUtterance(this.userInput);
        // eslint-disable-next-line no-unused-vars
        sInstance.onend = function (event) {
          console.log('SpeechSynthesisUtterance.onend');
        }
        // eslint-disable-next-line no-unused-vars
        sInstance.onerror = function (event) {
          console.error(event);
        }
        sInstance.pitch = this.pitch;
        sInstance.rate = this.rate;
        this.synth.speak(sInstance);
      } else {
        this.validation = true;
      }
      this.userInput = '';
    },


  },
  data(){
    return {
      firebaseStarted : "false",
      forceReload : 1,
      userInput: '',
      pitch: 1,
      rate: 1,
      synth: window.speechSynthesis,
      validation: false
    }
  }
};
</script>