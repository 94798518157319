<template>
  <div class="hello">
    <q-card class="my-card">
      <q-card-section>
        <q-item-label class="text-h3">
          Welcome to your messaging Inbox
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-list bordered>
          <q-item :href="'/message/'+message.user_id+'/'+message.case_id"  v-for="message in messages" :key="message.id" class="q-my-sm" clickable v-ripple>
            <q-item-section avatar>
              <q-avatar color="primary" text-color="white">
                {{ message.initials }}
              </q-avatar>
            </q-item-section>
              <q-item-section>
                <q-item-label>{{ message.from_user }}</q-item-label>
                <q-item-label v-if="message.case_id!=='0'">Case:ID {{ message.case_id }}</q-item-label>
                <q-item-label caption lines="1">Unread: {{ (message.message_count - message.read_messages) }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon v-if="(message.message_count - message.read_messages)===0" name="chat_bubble" color="gray"></q-icon>
                <q-icon v-if="(message.message_count - message.read_messages)!==0" name="chat_bubble" color="green"></q-icon>
              </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import axios from "axios";
import { Notify } from 'quasar'
import NoSleep from 'nosleep.js';

export default {
  name: 'PageInbox',
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
    const router = useRouter();
    let token = JSON.parse( localStorage.getItem('token') );
    let user_id = JSON.parse( localStorage.getItem('user_id') );
    if( !token ){
      router.push({ name: "login"})
    }else{
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/tasks?task_owner='+user_id+'&task_status='+5)
          .then((res) => {
            res.data.data.forEach(function(task) {
                if(task.status_id==="5"){
                  Notify.create({
                    color: 'orange-5',
                    textColor: 'white',
                    icon: 'warning',
                    message: 'You are still enroute to task: '+task.id,
                    timeout: 10000,
                  })
                  let url = '/execute-task/'+task.id;
                  router.push({ path: url})
                }
              if(task.status_id==="8"){
                Notify.create({
                  color: 'orange-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'You are still busy with checklist for task: '+task.id,
                  timeout: 10000,
                })
                let url = '/execute-task/'+task.id;
                router.push({ path: url})
              }
            });
          })
          .catch(() => {
          }).finally(() => {

      });
    }
  },
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  data(){
    return {
      messages : Object,
      draw_list : 0,
    }
  },
  mounted(){
     this.getMessages();
  },
  methods: {
    getMessages() {
      console.log(this.user_id);
      let user_id =this.user_id;
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/messages?user_id=' + user_id)
          .then((res) => {
            this.messages = res.data.data;
            this.messages.forEach((message,index) => {
              this.messages[index].initials = message.from_user.split(" ").map((n)=>n[0]).join(".");
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    deleteMessage(index) {
      let url = process.env.VUE_APP_API_BASE_URL+'/api/cases/messages/' + index;
      axios.delete(url)
          .then(() => {
            this.getMessages();
          })
          .catch(() => {
          }).finally(() => {
              this.getMessages();
          });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

