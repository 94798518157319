<template>
  <div class="scrollTarget">
    <q-card class="my-card">
      <q-card-section class="bg-grey-3">
        <q-item-label class="text-left text-h6">
          {{ this.from_user }}
          <span v-if="this.case_id !== '0'"> about Case : {{this.case_id}}</span>
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-list v-for="message in messages" :key="message.id" class="q-my-sm" bordered v-ripple separator aria-colcount="1">
          <q-item v-ripple>
            <!-- User Name -->
            <q-item-section>
              <q-item-label overline>
                {{ getNameById(message.from) }}
              </q-item-label>
            </q-item-section>
            <!-- Created At and Read Status Icons in the same row -->
            <q-item-section>
              <q-item-label caption>{{ message.created_at }}</q-item-label>
            </q-item-section>

          </q-item>
          <q-item>
            <!-- Message Content -->
            <q-item-section class="q-pt-sm">
              <q-item-label caption>
                {{ message.message }}
                <q-item-section side class="float-right">
                  <div class="flex items-center">
                    <q-icon v-if="parseInt(message.from) === parseInt(user_id)" name="check" color="blue"></q-icon>
                    <q-icon v-if="parseInt(message.from) === parseInt(user_id) && parseInt(message.read) > 0" name="check" color="blue"></q-icon>
                  </div>
                </q-item-section>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
    <q-footer bordered class="bg-white text-primary" elevated >
      <q-form
          method="post" @submit="this.sendNewMessage()"
          class="q-gutter-md">
        <q-list>
          <q-item>
            <q-item-section>
              <q-input v-model="this.reply_message" label="Message" :dense="true"></q-input>
            </q-item-section>
            <q-item-section thumbnail style="margin-right: 50px">
              <q-btn type="submit" round color="secondary" icon="navigation"></q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-form>
    </q-footer>
  </div>
</template>

<script>
import {useRoute, useRouter} from 'vue-router'
import firebaseApp from "@/firebaseConfig";
import axios from "axios";
import { Notify } from 'quasar'
import NoSleep from 'nosleep.js';
import { scroll } from 'quasar'
import { getMessaging,getToken,onMessage } from "firebase/messaging";
const messaging = getMessaging(firebaseApp);
// eslint-disable-next-line no-unused-vars

export default {
  name: 'PageInbox',
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
    const router = useRouter();
    let token = JSON.parse( localStorage.getItem('token') );
    if( !token ){
      router.push({ name: "login"})
    }else{
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
  },
  data(){
    return {
      messages : Object,
      draw_list : 0,
      parameter_id : null,
      case_id : 0,
      message_receivedVar : localStorage.getItem('message_received'),
      reply_subject : null,
      reply_message : null,
      from_user : null,
      to_user: null,
      users : [],
    }
  },
  mounted() {
    this.setupParameters();
    this.initializeFirebase();
    this.getMessages();
  },
  computed: {
    messageCount() {
      return this.messages.length;
    },
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  watch:{
    messageCount: function(){
      this.scrollToNewest();
      this.markMessagesAsRead(this.messages);
    }
  },
  methods: {
    registerToken(token) {
      let axios_token = JSON.parse( localStorage.getItem('token') );
      if( axios_token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + axios_token;
      }
      axios.post(
          process.env.VUE_APP_API_BASE_URL+"/api/firebase",
          {
            token: token
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json"
            }
          }
      ).then(() => {
      })
    },
    initializeFirebase() {
      getToken(messaging, { vapidKey: process.env.VUE_APP_vapid_Key })
          .then((token) => {
            console.log("Token is "+token);
            this.registerToken(token)
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
      onMessage(messaging, () => {
        this.playSound();
        this.getMessages();
      });
    },
    playSound () {
      let data = { soundurl : 'https://api.blackwidow.org.za/sos.mp3'}
      let audio = new Audio(data.soundurl);
      audio.play();
    },
    scrollToNewest() {
      console.log("Scrolling");
      this.$nextTick(() => {
        const targetElement = window;
        const offset = 100000; // Scroll down 200px
        const duration = 500; // Over half a second
        scroll.setVerticalScrollPosition(targetElement, offset, duration);
      })
    },
    setupParameters() {
      const route = useRoute()
      this.parameter_id = route.params.id;
      this.case_id = route.params.case_id;
      localStorage.setItem( 'from_user_id', JSON.stringify(this.parameter_id) );
    },
    getNameById(userID) {
      const record = this.users.find(item => item.id === parseInt(userID));
      return record ? record.name : null;
    },
    getUsers(){
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/users')
          .then((res) => {
            this.users = res.data.data;
            this.users.forEach((user) => {
              let user_id = this.user_id;
              let from_user_id = JSON.parse( localStorage.getItem('from_user_id') );
              if(user.id===parseInt(from_user_id)){
                this.from_user = user.name;
              }
              if(user.id===user_id){
                this.to_user = user.name;
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    sendNewMessage(){
      let user_id =this.user_id;
      let to_user = this.parameter_id;
      let subject = this.reply_subject;
      let message = this.reply_message;
      let message_type = 0;
      let case_id = this.case_id;
      let file_data = new FormData();
      file_data.append("action", "sendNewMessage")
      file_data.append("message",message);
      file_data.append("from_user", user_id)
      file_data.append("to_user", to_user);
      file_data.append("subject", subject);
      file_data.append("message_type", message_type);
      file_data.append("case_id", case_id);
      this.reply_subject = '';
      this.reply_message = '';
      let file_config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/messages/', file_data, file_config)
          .then(() => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'green-5',
              textColor: 'white',
              icon: 'success',
              message: 'Message sent successfully !! '
            });
            this.messages.push({
              from_user: this.to_user,
              message: message,
              created_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              from: user_id,
              read: '1'
            });
          })
          .catch((error) => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Failed retrieving messages !! ' + error
            })
          }).finally(() => {
        //Perform action in always
      });
    },

    getMessages() {
      let user_id =this.user_id;
      axios.get(process.env.VUE_APP_API_BASE_URL+'/api/cases/messages?to=' + user_id + '&from='+ this.parameter_id + '&case_id='+this.case_id)
          .then((res) => {
            this.messages = res.data.data;
            this.messages.forEach((message,index) => {
              this.messages[index].initials = message.from_user.split(" ").map((n)=>n[0]).join(".");
            });
          })
          .catch(() => {

          }).finally(() => {
        this.getUsers();
        this.scrollToNewest();

      });

    },

    markMessagesAsRead(messages){
      let user_id =this.user_id;
      messages = messages.filter(message => message.read !== '1');
      messages = messages.filter(message => parseInt(message.to) === parseInt(user_id));
      console.log("Messages to mark as read",messages.length)
      if(messages.length===0){
        console.log("No messages to mark as read");
      }else {
        if(JSON.stringify(messages)!==undefined){
          let file_data = new FormData();
          file_data.append("action", "markMessagesAsRead")
          file_data.append("messages",JSON.stringify(messages));
          file_data.append("user_id", user_id)
          let file_config = {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          }
          axios.post(process.env.VUE_APP_API_BASE_URL+'/api/cases/messages/', file_data, file_config)
              .then(() => {
                this.getMessages();
              })
              .catch((error) => {
                Notify.create({
                  progress: true,
                  timeout: 1000,
                  position: 'center',
                  color: 'red-5',
                  textColor: 'white',
                  icon: 'warning',
                  message: 'Failed retrieving messages !! ' + error
                })
              }).finally(() => {
            this.$store.dispatch('updateUnreadMessageCounter')
          });
        }
      }
    },
    deleteMessage(index) {
      let url = process.env.VUE_APP_API_BASE_URL+'/api/cases/messages/' + index;
      axios.delete(url)
          .then(() => {
            this.getMessages();
          })
          .catch(() => {
          }).finally(() => {
        this.getMessages();
      });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

